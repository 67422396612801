// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBqIm3ptTfBRXnHYYZ6Fv6qx92bXZGKsGA',
    authDomain: 'reviewapp-15386.firebaseapp.com',
    databaseURL: 'https://reviewapp-15386.firebaseio.com',
    projectId: 'reviewapp-15386',
    storageBucket: 'reviewapp-15386.appspot.com',
    messagingSenderId: '2947536868',
    appId: '1:2947536868:web:21fe349f9701eb3e'
  },

  MAGIC_BELL_API_KEY: '0de2c8b800ca9bbd05343f42c1e6d9f7c3794761',

  // Demo Serve //
  // SERVER_URL: 'http://demo.sistemaoe.com.mx',
  // SERVER_NETCORE: 'http://general-demo.sistemaoe.com.mx',
//  SERVER_CALLCENTER: 'http://call-center-demo.sistemaoe.com.mx',
  // SERVER_TASKS: 'http://tasks-demo.sistemaoe.com.mx',
  // SERVER_CONFIG: 'http://configuration-demo.sistemaoe.com.mx',
  // SERVER_REPORT: 'https://report.sistemaoe.com.mx',
  // SERVER_WAREHOUSE: 'http://warehouse-demo.sistemaoe.com.mx',
  // SERVER_ENERGY: 'http://energy-demo.sistemaoe.com.mx',
  // SERVER_SMART: 'http://smart-demo.sistemaoe.com.mx',

  // Production //
  SERVER_URL: 'https://api.sistemaoe.com.mx',
  SERVER_NETCORE: 'https://general-views.sistemaoe.com.mx',
  SERVER_CALLCENTER: 'https://call-center.sistemaoe.com.mx',
  SERVER_TASKS: 'https://tasks.sistemaoe.com.mx',
  SERVER_REPORT: 'https://report.sistemaoe.com.mx',
  SERVER_CONFIG: 'https://configuration.sistemaoe.com.mx',
  SERVER_WAREHOUSE: 'https://warehouse.sistemaoe.com.mx',
  SERVER_ENERGY: 'https://energy.sistemaoe.com.mx',
  SERVER_SMART: 'https://smart.sistemaoe.com.mx',
  STATUS_LAMBDA_URL: 'https://534l4j7h1h.execute-api.us-east-2.amazonaws.com/default/',
  SERVER_IOS: 'https://ios.sistemaoe.com.mx',

  // Localhost //
  // SERVER_URL: 'http://localhost:3000',
  // SERVER_NETCORE: 'http://localhost:5001',
  // SERVER_CALLCENTER: 'http://localhost:5002',
  // SERVER_TASKS: 'http://localhost:5003',
  // SERVER_CONFIG: 'http://localhost:5004',
  // SERVER_REPORT: 'http://localhost:5003',
  // SERVER_WAREHOUSE: 'http://localhost:5006',
  // SERVER_ENERGY: 'http://localhost:5010',
  // SERVER_SMART: 'http://localhost:5011',
  // SERVER_IOS: '',
  // STATUS_LAMBDA_URL: 'https://534l4j7h1h.execute-api.us-east-2.amazonaws.com/default/',

  iOSIPA: 'itms-services://?action=download-manifest&url=https://s3.us-east-2.amazonaws.com/oe-angular-app/manifest.plist',
  GMAP: 'AIzaSyBub7__sIkijFK-KDiZR0nYlgx-MKikHYY',
  SESSION_COOKIE_KEY: 'oe-session-canary',
  CURRENT_PROJECT_COOKIE: 'oe-current-project-canary',
  APIKEY_GOOGLE_MAPS: 'AIzaSyDrwUaq8DssSgCn8QxRBTuPB_JN1y_eB9o'
  
};
