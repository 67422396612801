import { RouteInfo } from './sidebar.metadata';
import { RoleEnum } from '@models/role-enum'
import { PhasesEnum } from '@app/models/phases-enum';
import { ProjectPhasesEnum } from '@app/models/project_phases-enum';
import { Role } from '@app/models/role';

export const ROUTES: RouteInfo[] = [
  {
    path: 'users',
    title: 'Admin Usuarios',
    icon: 'assets/img/svg/ic_admin.svg',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente],
    phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
  },
  // {
  //   path: 'censos',
  //   title: 'Censos',
  //   icon: '',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente],
  //   phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
  // },
  {
    path: 'tasks',
    title: 'Tareas',
    icon: 'assets/img/svg/ic_tareas.svg',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum.Analista],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'troops',
    title: 'Cuadrillas',
    icon: '?fa fa-id-card',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum.Almacén],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'general-view',
    title: 'Vista General',
    icon: '?fa fa-globe',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Call Center"], RoleEnum.Analista, RoleEnum.Cliente],
    phases: [ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'luminaire-status',
    title: 'Concesionadas',
    icon: '?fa fa-map-signs ',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'installation',
    title: 'Instalaciones',
    icon: '?fa fa-map-marker',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      {
        path: 'installation/pending',
        title: 'Pendientes',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'installations',
        title: 'Instalaciones',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      }
    ]
  },
  {
    path: 'smart',
    title: 'Smart',
    icon: '?fa fa-bolt',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      {
        path: 'smart/dashboard',
        title: 'Dashboard',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'smart/schedules',
        title: 'Horarios',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'smart/groups',
        title: 'Grupos',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'smart/devices',
        title: 'Dispositivos',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'smart/alerts',
        title: 'Alertas',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'smart/category-alerts/',
        title: 'Alertas por categoría',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
    ]
  },
  {
    path: '',
    title: 'Poligonos',
    icon: 'assets/img/svg/ic_polygon.svg',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño],
    submenu: [
      {
        path: 'areas/supermanzanas',
        title: 'Supermanzanas',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'areas/sectores',
        title: 'Sectores',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño],
        phases: [ProjectPhasesEnum["Fase 1"], ProjectPhasesEnum["Fase 2"], ProjectPhasesEnum["Fase 3"]]
      }

    ],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  // {
  //   path: 'survey-areas',
  //   title: 'Polígonos',
  //   icon: '?fa fa-object-ungroup',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Diseño],
  //   phases: [ProjectPhasesEnum["Fase 3"]]
  // },
  {
    path: 'guaranties',
    title: 'Garantias',
    icon: 'assets/img/svg/garantia-icon.svg',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Almacén],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      // {
      //   path: 'guaranties/guaranty_claims',
      //   title: 'Reclamos de Garantias',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
      //   phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      // },
      {
        path: 'guaranties/guaranty-report',
        title: 'Reportes',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
        phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      },
      {
        path: 'guaranties/guaranty-opinion',
        title: 'Dictamen',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
        phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      },
      // {
      //   path: 'guaranties/judment_claims',
      //   title: 'Dictamen',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
      //   phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      // }
    ],
  },
  {
    path: 'virtual-circuits',
    title: 'Circuitos',
    icon: '?fa fa-connectdevelop',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Analista],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      // {
      //   path: 'virtual-circuits/virtual_circuits',
      //   title: 'Circitos',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"]],
      //   phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      // }
    ],
  },
  {
    path: 'call-center',
    title: 'Operación',
    icon: '?fa fa-gears',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum['Cliente'], RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum.Analista],
    phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]],
    submenu: [
      {
        path: 'call-center/incidents',
        title: 'Incidencias',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum["Call Center"], RoleEnum.Analista],
        phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      },
      {
        path: 'call-center/incidents/incidents-luminaires-route4me',
        title: 'Rutas Optimizadas (- . -")',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum["Call Center"], RoleEnum.Analista],
        phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
      },
      {
        path: 'call-center/repair-tasks',
        title: 'Tareas de reparación',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum.Analista],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'call-center/repair-task',
        title: 'Tareas de reparación',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum["Supervisor de cuadrilla"], RoleEnum["Oficial de cuadrilla"], RoleEnum.Analista],
        phases: [ProjectPhasesEnum["Fase 2"]]
      },
      {
        path: 'call-center/report-luminaire',
        title: 'Levantar Reporte',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum.Analista],
        phases: []
      },
      {
        path: 'call-center/tickets',
        title: 'Tickets',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Cliente, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum.Analista],
        phases: []
      },
      {
        path: 'call-center/dashboard',
        title: 'Call Center Dashboard',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"], RoleEnum.Analista],
        phases: []
      }
    ]
  },
  {
    path: 'stats',
    title: 'Reportes',
    icon: '?fa fa-bar-chart',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum["Call Center"]],
    phases: [],
    submenu: [
      {
        path: 'reports/tickets-stats',
        title: 'Seguimiento Tickets',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      },
      {
        path: 'reports/log-maintenance',
        title: 'Bitacora Mtto.',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'reports/individual-log',
        title: 'Reporte Mtto.',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'reports/tasks-repairs',
        title: 'Reparaciones',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      // {
      //   path: 'reports/oficial-active-tasks',
      //   title: 'Tarea por Oficial',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: [],
      //   phases: [ProjectPhasesEnum["Fase 3"]]
      // },
      {
        path: 'reports/oficial-efficienty',
        title: 'Eficiencia Oficiales',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'reports/operational-dashboard',
        title: 'Dashboard Operación',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      },
      {
        path: 'reports/report-rpu',
        title: 'Infomación de Rpu',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: [ProjectPhasesEnum["Fase 3"]]
      }
    ]
  },
  {
    path: 'loaded-values',
    title: 'Valores Cargados',
    icon: '?fa fa-cloud-download',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'inventory',
    title: 'Almacen',
    icon: '?fa fa-archive',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Compras, RoleEnum.Residente, RoleEnum['Ing. de proyecto']],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      {
        path: 'inventory/summary',
        title: 'Resumen',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Residente, RoleEnum['Ing. de proyecto']],
        phases: []
      },
      {
        path: 'inventory/category',
        title: 'Inventario',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Compras, RoleEnum.Residente, RoleEnum['Ing. de proyecto']],
        phases: []
      },
      {
        path: 'inventory/cart',
        title: 'Carrito',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Compras],
        phases: []
      },
      {
        path: 'inventory/requisitions',
        title: 'Solicitudes',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Compras, RoleEnum.Residente, RoleEnum['Ing. de proyecto']],
        phases: []
      },
      {
        path: 'inventory/recovered-materials',
        title: 'Material Recuperado',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Compras],
        phases: []
      },
      {
        path: 'inventory/track-matrial',
        title: 'Movimientos Almacen',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén, RoleEnum.Residente, RoleEnum.Compras],
        phases: []
      },
      {
        path: 'inventory/logs',
        title: 'Log de Movimientos',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum.Almacén],
        phases: []
      }
    ]
  },
  {
    path: 'power',
    title: 'Potencias',
    icon: '?fa fa-sliders',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Analista],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      {
        path: 'power/administration',
        title: 'Administración',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"]],
        phases: []
      },
      {
        path: 'power/assignment',
        title: 'Asignación',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum.Analista],
        phases: []
      },
      {
        path: 'power/marker-color',
        title: 'Marcadores',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente],
        phases: []
      },
    ]
  },
  {
    path: 'energy',
    title: 'Facturación',
    icon: '?fa fa-file',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"], RoleEnum.Residente, RoleEnum['Call Center']],
    phases: [ProjectPhasesEnum["Fase 3"]],
    submenu: [
      {
        path: 'energy/dashboard-cfe',
        title: 'Dashboard CFE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      },
      {
        path: 'energy/cfe-import-files',
        title: 'Importación CFE',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      },
      {
        path: 'energy/cfe-tariffs',
        title: 'Tarifas',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      },
      {
        path: 'energy/rpus',
        title: 'Rpus',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      },
      {
        path: 'energy/alerts',
        title: 'Alertas',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [],
        phases: []
      }
    ]
  },
  {
    path: 'status',
    title: 'Estatus',
    icon: '?fa fa-solid fa-signal',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador, RoleEnum["Ing. de proyecto"]],
    phases: []
  },
  {
    path: 'admin',
    title: 'Admin',
    icon: '?fa fa-gear',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum.Administrador],
    phases: [ProjectPhasesEnum["Fase 3"], ProjectPhasesEnum["Fase 2"]]
  },
  {
    path: '/download-app',
    title: 'Descargar App',
    icon: '?fa fa-apple',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum['Supervisor de cuadrilla']],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'tableau-dashboard',
    title: 'Dashboard',
    icon: '?fa fa-bar-chart',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum['Cliente']],
    phases: [ProjectPhasesEnum["Fase 3"]]
  },
  {
    path: 'tableau-dashboard-ipad-view',
    title: 'Dashboard iPad',
    icon: '?fa fa-tablet',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: [RoleEnum['Cliente']],
    phases: [ProjectPhasesEnum["Fase 3"]]
  }
];
