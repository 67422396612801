import { Component, OnInit } from '@angular/core';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-general-view',
  templateUrl: './general-view.component.html',
  styleUrls: ['./general-view.component.scss']
})
export class GeneralViewComponent implements OnInit {
  isClient : Boolean;

  constructor(
    private authService: AuthService
  ) {
    this.isClient = this.authService.isClient();
   }

  ngOnInit() { }



 // ngOnDestroy() {
  //  this.destroy$.next(true);
 //   this.destroy$.unsubscribe();
 // }



}
