import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { GetRepairResponse, Repair } from '@models/repair';
import { RepairV2 } from '@models/repairv2';
import { AuthService } from '@shared/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class RepairsService {
  isClient: boolean;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.isClient = this.authService.isClient(); // Verifica si el usuario es un cliente
  }

  getRepair(repairId: string): Observable<Repair> {
    if (this.isClient) {
      return this.httpClient.get<GetRepairResponse>(
        `${environment.SERVER_CALLCENTER}/api/Tickets/GetRepair?id=${repairId}`,
      ).pipe(
        map(data => new RepairV2(data))
      );
    } else {
      return this.httpClient.get<GetRepairResponse>(
        `${environment.SERVER_URL}/repairs/${repairId}`
      ).pipe(
        map(data => new Repair(data.repair))
      );
    }
  }
  
  updateRepair(repairID: string, formData: FormData): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/repairs/${repairID}`, formData
    );
  }
}