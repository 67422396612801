import { SmartDeviceSingleDetails, SmartDevicesMarkers, SimpleSmartGroup, Readdings30Days } from './../../../models/smart-devices';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Device, GetDevices } from '@app/models/devices';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { SmartDevicesTable } from '@app/models/smart-devices';
import { SmartDeviceGeneralDetails } from '@app/models/smart-devices';
import { AuthService } from '@app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  deleteCircuitDevice(circuit_id: string) {

    const param = new HttpParams()
      .set('circuit_id', circuit_id);
    return this.httpClient.post(`${environment.SERVER_SMART}/api/SmartDevices/delete-circuit-device`, {}, { params: param });

  }

  disableDevice(device_id: string) {

    const param = new HttpParams()
      .set('device_id', device_id);
    return this.httpClient.post(`${environment.SERVER_SMART}/api/SmartDevices/disable-device`, {}, { params: param });

  }

  

  getDevicesTable(page, device, circuit, device_status, circuit_status, orderBy, group_id, delay, is_active) {

    const httpParams = new HttpParams()
      .set('page', page)
      .set('device', device)
      .set('circuit', circuit)
      .set('device_status', device_status)
      .set('circuit_status', circuit_status)
      .set('orderBy', orderBy)
      .set('group_id', group_id)
      .set('delay', delay)
      .set('is_active', is_active)
      ;

    return this.httpClient.get<GenericTable<SmartDevicesTable>>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDevicesTable`,
      { params: httpParams }
    ).pipe(
      map(table => new GenericTable<SmartDevicesTable>(table))
    );
  }

  setfixedTime(device_id: string, on: string, off: string) {
    const httpParams = new HttpParams()
    .set('device_id', device_id)
    .set('off_time', off)
    .set('on_time', on);

    return this.httpClient.get<SmartDeviceSingleDetails>(
      `${environment.SERVER_SMART}/api/SmartDevices/Fixed_Time`,
      { params: httpParams }
    );
  }

  getSingleDeviceDetail(device_id: string) {
    const httpParams = new HttpParams().set('device_id', device_id);

    return this.httpClient.get<SmartDeviceSingleDetails>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDeviceDetail`,
      { params: httpParams }
    );

  }

  updateDeviceGroup(device_id, group_id) {
    const httpParams = new HttpParams().set('device_id', device_id).set('group_id', group_id);

    return this.httpClient.get<SmartDeviceSingleDetails>(
      `${environment.SERVER_SMART}/api/SmartDevices/UpdateDeviceGroup`,
      { params: httpParams }
    );
  }

  updateDeviceSchedule(device_id, shcedule_id) {
    const httpParams = new HttpParams().set('device_id', device_id).set('schedule_id', shcedule_id);

    return this.httpClient.get<SmartDeviceSingleDetails>(
      `${environment.SERVER_SMART}/api/SmartDevices/UpdateDeviceSchedule`,
      { params: httpParams }
    );
  }

  getSimpleSmartGroups() {
    return this.httpClient.get<SimpleSmartGroup[]>(`${environment.SERVER_SMART}/api/SmartDevices/GetSmartGroups`);
  }

  GetOnAndOffDevices() {
    return this.httpClient.get<any[]>(`${environment.SERVER_SMART}/api/SmartDevices/GetOnAndOffDevices`);
  }

  getDevicesMarkers(device, circuit, device_status, circuit_status, group_id) {

    const httpParams = new HttpParams()
      .set('device', device)
      .set('circuit', circuit)
      .set('device_status', device_status)
      .set('circuit_status', circuit_status)
      .set('group_id', group_id);

    return this.httpClient.get<SmartDevicesMarkers[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDevicesMarkers`,
      { params: httpParams }
    );
  }

  Get_Reddings30Days(device_id) {
    const httpParams = new HttpParams()
      .set('device_id', device_id);
    return this.httpClient.get<any[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/get-device-rssi-30-days`, { params: httpParams });
  }


  getDeviceDetails(device_id: string) {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<SmartDeviceGeneralDetails>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDeviceDetails`, { params: httpParams });
  }

  getDeviceSensors(device_id: string) {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<any[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/DeviceSensors`, { params: httpParams });
  }

  getDeviceElectricReadings30Days(device_id: string, cant_days, days_before) {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
      .set('days_before', days_before)
      .set('cant_days', cant_days);
    return this.httpClient.get<any[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/DeviceElectricReadings30Days`, { params: httpParams });
  }
  getDeviceKwhsReadings(device_id: string) {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<any[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/DeviceKwhsReadings`, { params: httpParams });
  }

  getDeviceSimusage(device_id: string) {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDeviceSimusage`, { params: httpParams });
  }

  addDevice(form) {
    return this.httpClient.post(
      `${environment.SERVER_SMART}/api/SmartDevices/AddDevice`, form
    );
  }

  addAnnotation(form) {
    const httpHeaders = new HttpHeaders().append('X-User', this.authService.getUser().id)
    return this.httpClient.post(
      `${environment.SERVER_SMART}/api/SmartAnnotations/AddAnnotation`, form, { headers: httpHeaders }
    )
  }

  getGroupsList(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetGroupList`);
  }
  getSmartAnnotations(device_id: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/SmartAnnotations/GetSmartAnnotations`, { params: httpParams });
  }

  GetSmartAlertsAnnotations(device_id: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('device_id', device_id)
    return this.httpClient.get<any>(
      `${environment.SERVER_SMART}/api/SmartAnnotations/GetSmartAlertsAnnotations`, { params: httpParams });
  }

  getDevicesMapList(): Observable<GetDevices[]> {
    return this.httpClient.get<GetDevices[]>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetDevicesForMap`);
  }

  getDevicesList(form, page) {
    const httpParams = new HttpParams().set('page', page)
    return this.httpClient.post<GenericTable<GetDevices>>(
      `${environment.SERVER_SMART}/api/SmartDevices/GetAllDevices`, form, { params: httpParams }
    ).pipe(
      map(elements => new GenericTable<GetDevices>(elements))
    );
  }



  // getActiveMeter(id: string) {
  //   const httpParams = new HttpParams()
  //   .set('id', id)
  //   return this.httpClient.get(
  //     `${environment.SERVER_SMART}/api/SmartMeter/GetActiveMeter`, {params: httpParams});
  // }

  ActiveMeter(device_name, circuit_status) {
    const parameters = {
      fotocelda: false,
      control: circuit_status
    };

    const httpHeaders = new HttpHeaders()
      .append('auth-api', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjkzMzQzYTdlZTllZjVkMzE2ZDA0NzgiLCJpYXQiOjE2MDM0ODI2ODJ9.2raHW6dJRaQ9erPr087zlIfmmu8r18-O1afTxaVMj_c');

    return this.httpClient.post(`https://tools.greenapsis.com/api/meters/control/${device_name}`, parameters, { headers: httpHeaders });

  }
  UpdateDeviceGroup(form) {
    return this.httpClient.post(
      `${environment.SERVER_SMART}/api/SmartDevices/UpdateDeviceGroup`, form
    );
  }

  getWattages(): Observable<object> {
    return this.httpClient.get<object>(`${environment.SERVER_NETCORE}/api/GeneralView/GetWattages`);
  }


}
