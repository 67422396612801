import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SmartAlerts } from '@app/models/smart-alerts';
import { EditUserService } from '@app/pages/users/edit-user/edit-user.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EEXIST } from 'constants';
import { SmartAlertsService } from './smart-alerts.service';
import { IgetSectores, SendSector } from '../../../models/smart/sectors.model'

@Component({
  selector: 'app-smart-alerts',
  templateUrl: './smart-alerts.component.html',
  styleUrls: ['./smart-alerts.component.scss'],
  providers: [SmartAlertsService]
})
export class SmartAlertsComponent implements OnInit, OnChanges {

  title = 'push-notification';
  message;

  @ViewChild('contentMaterialModal') materialModal: any;
  @ViewChild('copyModal') copyModal: any;
  alertsTable: any;  // Usamos alertsTable en lugar de alerts para incluir paginación
  alertsSearchForm: FormGroup;
  alertForm: FormGroup;
  keyword = 'deviceName';
  solved: number;
  unsolved: number;
  selectSectores: IgetSectores[];
  sendSector: SendSector;

  // Variables de paginación
  pageSize = 25;
  pageIndex = 0;
  isInitialized; // La inicialización ha terminado


  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toaster: Toastr,
    private alertsService: SmartAlertsService
  ) {

    this.alertsSearchForm = fb.group({
      deviceName: [''],
      reference: [''],
      alertType: [''],
      orderBy: ['date'],
      alertStatus: [''],
      sector: ['']
    });
  }

  ngOnChanges(): void {
  }

  ngOnInit() {
    this.pageIndex = 1; // Inicializa la página en 1
    this.isInitialized = false; // Nueva bandera para evitar la llamada adicional
  
    this.alertsService.getSectores().subscribe(resp => {
      this.selectSectores = resp;
    }, error => {
      console.log(error);
    });
  
    this.alertForm = this.fb.group({
      id: [null],
      comment: [null],
      solveDate: [null],
      userName: [null],
    });
  
    this.onSearch();
    this.isInitialized = true; // La inicialización ha terminado
  }

  onEdit(alert) {
    console.log(alert);
    this.alertForm.patchValue({
      id: alert.id,
      comment: alert.comment,
      solveDate: alert.solveDate,
      userName: alert.userName,
    });

    this.modalService.open(this.materialModal, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  saveAlert() {
    const data = {
      id: this.alertForm.value.id,
      fixedAlert: true,
      comment: this.alertForm.value.comment,
    }

    this.alertsService.changeDeviceStatus(data).subscribe(response => {
      this.toaster.success('Se agregó correctamente');
    }, error => {
      this.toaster.error('Error al agregar el grupo');
    });
    this.onSearch();
    this.alertForm.reset();
    this.modalService.dismissAll();
  }

  onSearch() {
    const filtro = this.alertsSearchForm.getRawValue();
    filtro.sector = this.sendSector;
    filtro.page = this.pageIndex - 1; 
    filtro.size = this.pageSize;

    this.alertsService.getAlertsList(filtro).subscribe(res => {
      this.alertsTable = res;  // Aquí recibimos la respuesta con los datos de alerta y paginación
      this.solved = this.alertsTable.elements.filter(x => x.alertStatus === true).length;
      this.unsolved = this.alertsTable.elements.filter(x => x.alertStatus === false).length;
    });
  }

  onClose() {
    this.alertForm.reset();
    this.modalService.dismissAll();
  }

  prueba2(prueba, array: IgetSectores[]) {
    array.forEach(element => {
      element.listado.forEach(ele => {
        if (ele.id === prueba) {
          this.sendSector = {
            type: ele.type,
            id: ele.id
          };
        }
      });
    });
  }

  pageChange(page: number) {
    if (this.pageIndex !== page) {
      this.pageIndex = page; // Mantén el índice basado en 1
      this.onSearch();       // Llama a la búsqueda con el índice convertido
    }
  }  
}