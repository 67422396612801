// Libraries Import
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FusionChartsModule } from 'angular-fusioncharts';
// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import * as chart from 'fusioncharts/fusioncharts.powercharts';
import * as widgets from 'fusioncharts/fusioncharts.widgets';

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, chart, FusionTheme, TimeSeries, charts, widgets);
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from '@shared/auth/auth.service';
import { AuthGuard } from '@shared/auth/auth-guard.service';
import { ProjectGuard } from '@shared/projects/project-guard.service';
import { RoleGuard } from '@shared/roles/role-guard.service';
import { ProjectService } from '@shared/projects/project.service';

import * as $ from 'jquery';
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrComponent } from '@shared/toastr/toastr.component';
import { Toastr } from '@shared/toastr/toastr.service'
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'

// Application Pipes Import
import { SiNoPipe } from '@pages/tasks/tasks-table/tasks-table.component';
// tslint:disable-next-line:max-line-length
import { getReference_idLuminairesPipe, getTicketsWithOutreferencePipe } from '@pages/call-center/repair-tasks-table/repair-tasks-table.component';
import { getCircuitsWithOutreferencePipe } from '@pages/energy/rpu-detail/rpu-detail.component'
import { getTicketsPipe } from '@pages/call-center/repair-tasks-table/repair-tasks-table.component';

// Application Components Import
import { LoginComponent } from '@pages/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { UsersComponent } from '@pages/users/users.component';
import { NewUserComponent } from '@pages/users/new-user/new-user.component';
import { TasksComponent } from '@pages/tasks/tasks.component';
import { NewQuickTaskComponent } from '@pages/tasks/new-quick-task/new-quick-task.component';
import { SelectProjectComponent } from '@pages/projects/select-project/select-project.component';
import { UserFormComponent } from '@pages/users/user-form/user-form.component';
import { EditUserComponent } from '@pages/users/edit-user/edit-user.component';
import { DownloadAppComponent } from '@pages/download-app/download-app.component';

// Application Interceptors
import { DashboardHeaderInterceptor } from '@shared/http/dashboard-header.interceptor';
import { ClientHeaderInterceptor } from '@shared/http/client-header.interceptor';
import { AuthenticationHeaderInterceptor } from '@shared/http/authentication-header.interceptor';
import { ProjectHeaderInterceptor } from '@shared/http/project-header.interceptor';

// Application Services
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { ChangePasswordComponent } from '@pages/change-password/change-password.component';
import { TasksTableComponent } from '@pages/tasks/tasks-table/tasks-table.component'
import { TaskComponent } from '@pages/tasks/task/task.component';
import { QuickSurveyComponent } from '@pages/tasks/task/type/quick-survey/quick-survey.component';
import { TroopsComponent } from '@pages/troops/troops.component';
import { TroopsTableComponent } from '@pages/troops/troops-table/troops-table.component';
import { NewTroopComponent } from '@pages/troops/new-troop/new-troop.component';
import { EditTroopComponent } from '@pages/troops/edit-troop/edit-troop.component';
import { TroopFormComponent } from '@pages/troops/troop-form/troop-form.component';
import { NewInfrastructureTaskComponent } from '@pages/tasks/new-infrastructure-task/new-infrastructure-task.component';
import { InfrastructureSurveyComponent } from '@pages/tasks/task/type/infrastructure-survey/infrastructure-survey.component';
import { EditCircuitComponent } from '@pages/circuits/edit-circuit/edit-circuit.component';
import { EditCabinetComponent } from '@pages/circuits/edit-circuit/edit-cabinet/edit-cabinet.component';
import { EditSubstationComponent } from '@pages/circuits/edit-circuit/edit-substation/edit-substation.component';
import { EditMeterComponent } from '@pages/circuits/edit-circuit/edit-meter/edit-meter.component';
import { EditWiringComponent } from '@pages/circuits/edit-circuit/edit-wiring/edit-wiring.component';
import { EditLuminaireComponent } from '@pages/luminaire/edit-luminaire/edit-luminaire.component';
import { EditLamppostComponent } from '@pages/luminaire/edit-luminaire/edit-lamppost/edit-lamppost.component';
import { EditArmComponent } from '@pages/luminaire/edit-luminaire/edit-arm/edit-arm.component';
import { EditBaseComponent } from '@pages/luminaire/edit-luminaire/edit-base/edit-base.component';
import { GeneralViewComponent } from '@pages/general-view/general-view.component';
import { SurveyAreasComponent } from '@pages/survey-areas/survey-areas.component';
import { ReportLuminaireComponent } from '@pages/report-luminaire/report-luminaire.component';
import { CheckReportComponent } from '@pages/check-report/check-report.component';
import { IncidentsComponent } from '@pages/call-center/incidents/incidents.component';
import { IncidentsTableComponent } from '@pages/call-center/incidents/incidents-table/incidents-table.component';
import { ShortenFolioPipe } from '@pages/call-center/incidents/shorten-folio.pipe';
import { IncidentsMapComponent } from '@pages/call-center/incidents/incidents-map/incidents-map.component';
import { NewRepairTaskComponent } from '@pages/tasks/new-repair-task/new-repair-task.component';
import { RepairTasksTableComponent } from '@pages/call-center/repair-tasks-table/repair-tasks-table.component';
import { RepairSurveyComponent } from '@pages/tasks/task/type/repair-survey/repair-survey.component';
import { AdminComponent } from '@pages/admin/admin.component';
import { ReportByUserComponent } from '@pages/call-center/report-by-user/report-by-user.component';
import { TicketsComponent } from '@pages/call-center/tickets/tickets.component';
import { TicketsTableComponent } from '@pages/call-center/tickets/tickets-table/tickets-table.component';
import { EditTicketComponent } from '@pages/call-center/tickets/edit-ticket/edit-ticket.component';
import { TicketTableComponent } from '@pages/call-center/tickets/edit-ticket/ticket-table/ticket-table.component';
import { TicketMapComponent } from '@pages/call-center/tickets/edit-ticket/ticket-map/ticket-map.component';
import { ChargedValuesComponent } from '@pages/charged-values/charged-values.component';
import { CategoryComponent } from '@pages/charged-values/category/category.component';
import { InventoryComponent } from '@pages/inventory/inventory.component';
import { InventoryCategoryComponent } from '@pages/inventory/inventory-category/inventory-category.component';
import { CartComponent } from '@pages/inventory/cart/cart.component';
import { RequisitionsComponent } from '@pages/inventory/requisitions/requisitions.component';
import { PendingComponent } from '@pages/inventory/requisitions/pending/pending.component';
import { SentComponent } from '@pages/inventory/requisitions/sent/sent.component';
import { HistoryComponent } from '@pages/inventory/requisitions/history/history.component';
import { MaterialsTableComponent } from '@pages/inventory/requisitions/materials-table/materials-table.component';
import { RepairsComponent } from '@pages/repairs/repairs.component';
import { EditRepairComponent } from '@pages/repairs/edit-repair/edit-repair.component';
import { RecoveredMaterialsComponent } from '@pages/inventory/recovered-materials/recovered-materials.component';
// tslint:disable-next-line:max-line-length
import { RecoveredMaterialCategoryComponent } from '@pages/inventory/recovered-materials/recovered-material-category/recovered-material-category.component';
import { VersionsComponent } from '@pages/admin/versions/versions.component';
import { VersionComponent } from '@pages/admin/versions/version/version.component';
import { AdministerPowerComponent } from '@pages/powers/administer-power/administer-power.component';
import { AssignPowerComponent } from '@pages/powers/assign-power/assign-power.component';
import { NewInstallationTaskComponent } from '@pages/tasks/new-installation-task/new-installation-task.component';
import { InstallationSurveyComponent } from '@pages/tasks/task/type/installation-survey/installation-survey.component';
import { InstallationComponent } from '@pages/tasks/task/type/installation-survey/installation/installation.component';
import { LuminairesPendingInstallationComponent } from '@pages/luminaires-pending-installation/luminaires-pending-installation.component';
// tslint:disable-next-line:max-line-length
import { LuminairesPendingTableComponent } from '@pages/luminaires-pending-installation/luminaires-pending-table/luminaires-pending-table.component';
import { LuminairesPendingMapComponent } from '@pages/luminaires-pending-installation/luminaires-pending-map/luminaires-pending-map.component';
import { SummaryComponent } from './pages/inventory/summary/summary.component';
import { KpiQuickTaskComponent } from './pages/kpis/kpi-quick-task/kpi-quick-task.component';
import { ChartsModule } from 'ng2-charts';
import { KpiInfrastructureTaskComponent } from './pages/kpis/kpi-infrastructure-task/kpi-infrastructure-task.component';
import { KpiInstallationTaskComponent } from './pages/kpis/kpi-installation-task/kpi-installation-task.component';
import { TicketsStatsComponent } from './pages/call-center/tickets-stats/tickets-stats.component';
import { RepairTasksPhaseComponent } from './pages/call-center/repair-tasks-phase/repair-tasks-phase.component';
import { RepairsPhaseComponent } from './pages/repairs-phase/repairs-phase.component';
import { LogMaintanenceComponent } from './pages/reports/log-maintanence/log-maintanence.component';
import { CfeFilesImportComponent } from './pages/energy/cfe-files-import/cfe-files-import.component';
import { RpusComponent } from './pages/energy/rpus/rpus.component';
import { UsersActiveTasksComponent } from './pages/reports/users-active-tasks/users-active-tasks.component';
import { TarrifsComponent } from './pages/energy/tarrifs/tarrifs.component';
import { UserEfficiencyComponent } from './pages/reports/user-efficiency/user-efficiency.component';
import { ImportDetailComponent } from './pages/energy/import-detail/import-detail.component';
import { IndividualLogComponent } from './pages/reports/individual-log/individual-log.component';
import { RpuDetailComponent } from './pages/energy/rpu-detail/rpu-detail.component';
import { DashboardCfeComponent } from './pages/energy/dashboard-cfe/dashboard-cfe.component';
import { CapitalizePipe } from './shared/pipe/capitalize.pipe';
import { PriorityPipe } from './shared/pipe/priority.pipe';
import { DashCasePipe } from './shared/pipe/dash.pipe';
import { ShortenCommentaryPipe } from './shared/pipe/shorten-commentary.pipe';
import { AlertComponent } from './pages/energy/alert/alert.component';
import { AlertsTypeComponent } from './pages/energy/alerts-type/alerts-type.component';
import { IndividualDashboardComponent } from './pages/reports/operation-dashboard/individual-dashboard/individual-dashboard.component';
import { InstallationsComponent } from './pages/installations/installations.component';
import { MovementsLogComponent } from './pages/inventory/movements-log/movements-log.component';
import { OperationDashboardComponent } from './pages/reports/operation-dashboard/operation-dashboard.component';
import { TrackMaterialComponent } from './pages/inventory/track-material/track-material.component';
import { RequestMaterialComponent } from './pages/inventory/track-material/request-material/request-material.component';
import { ExtractedMaterialComponent } from './pages/inventory/track-material/extracted-material/extracted-material.component';
import { TrackedMaterialComponent } from './pages/inventory/track-material/tracked-material/tracked-material.component';
import { MaterialReqComponent } from './pages/inventory/track-material/request-material/material-req/material-req.component';
import { GuarantyClaimsComponent } from './pages/guaranties/guaranty_claims/guaranty-claims/guaranty-claims.component';
import { JudmentComponent } from './pages/guaranties/judment_claims/judment/judment.component';
import { GuarantyphotocellsComponent } from './pages/opinion/guarantyphotocells/guarantyphotocells.component';
import { GuarantyluminaireComponent } from './pages/opinion/guarantyphotocells/guarantyluminaire/guarantyluminaire.component';

// AoT requires an exported function for factories
import { CallCenterDashboardComponent } from './pages/call-center/call-center-dashboard/call-center-dashboard.component';
import { CountDowmComponent } from './pages/call-center/incidents/incidents-table/count-dowm/count-dowm.component';
import { LuminairesStatusComponent } from './pages/powers/luminaires-status/luminaires-status.component';
import { LuminaireStatusComponent } from './pages/luminaire-status/luminaire-status.component';
import { GeneralViewProgressComponent } from './pages/general-view/general-view-progress/general-view-progress.component';
import { GeneralViewStatusComponent } from './pages/general-view/general-view-status/general-view-status.component';
import { GeneralViewWattageComponent } from './pages/general-view/general-view-wattage/general-view-wattage.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReportRpuComponent } from './pages/reports/report-rpu/report-rpu.component';

import { SchedulesComponent } from './pages/smart/schedules/schedules.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GroupsComponent } from './pages/smart/groups/groups.component';
import { DevicesComponent } from './pages/smart/devices/devices.component';
import { DeviceDetailsComponent } from './pages/smart/devices/device-details/device-details.component';
import { DevicesTableComponent } from './pages/smart/devices/devices-table/devices-table.component';
import { DevicesMapComponent } from './pages/smart/devices/devices-map/devices-map.component';


import { from } from 'rxjs';
import { DeviceGraphicsComponent } from './pages/smart/devices/device-details/device-graphics/device-graphics.component';
import { DeviceCalendarComponent } from './pages/smart/devices/device-details/device-calendar/device-calendar.component';
import { GroupDetailsComponent } from './pages/smart/groups/group-details/group-details.component';
import { DeviceSimComponent } from './pages/smart/devices/device-details/device-sim/device-sim.component';

import { VirtualCircuitsComponent } from './pages/virtual-circuits/virtual-circuits.component';
// tslint:disable-next-line:max-line-length
import { LuminairesVirtualCircuitComponent } from './pages/virtual-circuits/luminaires-virtual-circuit/luminaires-virtual-circuit.component';
import { AddCircuitComponent } from './pages/virtual-circuits/add-circuit/add-circuit.component';
import { SmartAlertsComponent } from './pages/smart/smart-alerts/smart-alerts.component';
import { SmartAlertsDetailsComponent } from './pages/smart/smart-alerts/smart-alerts-details/smart-alerts-details.component';
import { SurveyAreaComponent } from './pages/survey-area/survey-area.component';
import { SmartDashboardComponent } from './pages/smart/smart-dashboard/smart-dashboard.component';
import { DeviceKwhsComponent } from './pages/smart/devices/device-details/device-kwhs/device-kwhs.component';
import { TroopRecoveryItemsComponent } from './pages/troops/troop-recovery-items/troop-recovery-items.component';
import { TroopMaterialItemsComponent } from './pages/troops/troop-material-items/troop-material-items.component';
import { DeviceSensorsComponent } from './pages/smart/devices/device-details/device-sensors/device-sensors.component';
import { MarkerColorComponent } from './pages/powers/marker-color/marker-color.component';
import { DeviceGraphicsRssiComponent } from './pages/smart/devices/device-details/device-graphics-rssi/device-graphics-rssi.component';
import { GuarantyReportComponent } from './pages/guaranties/guaranty-report/guaranty-report.component';
import { DetailGuarantyReportComponent } from './pages/guaranties/guaranty-report/detail-guaranty-report/detail-guaranty-report.component';
import { GuarantyOpinionComponent } from './pages/guaranties/guaranty-opinion/guaranty-opinion.component';
import { AssignatedOpinionComponent } from './pages/guaranties/guaranty-opinion/assignated-opinion/assignated-opinion.component';

import { ConfimationStatusComponent } from './pages/guaranties/guaranty-opinion/assignated-opinion/confimation-status/confimation-status.component';
import { SectoresComponent } from './pages/sectores/sectores.component';
import { EditUbicacionComponent } from './pages/luminaire/edit-luminaire/edit-ubicacion/edit-ubicacion.component';
import { IncidentsLuminairesRoute4meComponent } from './pages/call-center/incidents/incidents-luminaires-route4me/incidents-luminaires-route4me.component';
import { CensosComponent } from './pages/censos/censos.component';
import { IncidentsLuminairesRoutesRoute4meComponent } from './pages/call-center/incidents/incidents-luminaires-routes-route4me/incidents-luminaires-routes-route4me.component';
import { RepairTasksComponent } from './pages/reports/repair-tasks/repair-tasks.component';
import { StatusComponent } from './pages/status/status.component';
import { CategoryAlertsComponent } from './pages/smart/category-alerts/category-alerts.component';
import { AlertsByTypeComponent } from './pages/smart/alerts-by-type/alerts-by-type.component';
import { TableauDashboardComponent } from './shared/components/tableau-dashboard/tableau-dashboard.component';
import { TableauDashboardViewComponent } from './pages/tableau/dashboard/tableau-dashboard-view.component';
import { TableauDashboardIpadViewComponent } from './pages/tableau/ipad/tableau-dashboard-ipad-view.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { KpisComponent } from './pages/call-center/incidents/kpis/kpis.component';


// firebase
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
// import { environment } from '@environments/environment';
// import { MessagingService } from './pages/smart/smart-alerts/messaging.service';
// import { AsyncPipe } from '../../node_modules/@angular/common';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        EditUbicacionComponent,
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        LoginComponent,
        ProfileComponent,
        ToastrComponent,
        UsersComponent,
        NewUserComponent,
        TasksComponent,
        NewQuickTaskComponent,
        ChangePasswordComponent,
        TasksTableComponent,
        TaskComponent,
        QuickSurveyComponent,
        SelectProjectComponent,
        UserFormComponent,
        EditUserComponent,
        TroopsComponent,
        TroopsTableComponent,
        NewTroopComponent,
        EditTroopComponent,
        TroopFormComponent,
        SiNoPipe,
        getReference_idLuminairesPipe,
        getTicketsPipe,
        getTicketsWithOutreferencePipe,
        CapitalizePipe,
        PriorityPipe,
        ShortenCommentaryPipe,
        getCircuitsWithOutreferencePipe,
        DashCasePipe,
        NewInfrastructureTaskComponent,
        InfrastructureSurveyComponent,
        EditCircuitComponent,
        EditCabinetComponent,
        EditSubstationComponent,
        EditMeterComponent,
        EditWiringComponent,
        EditLuminaireComponent,
        EditLamppostComponent,
        EditArmComponent,
        EditBaseComponent,
        GeneralViewComponent,
        SurveyAreasComponent,
        SurveyAreaComponent,
        DownloadAppComponent,
        ReportLuminaireComponent,
        CheckReportComponent,
        IncidentsLuminairesRoute4meComponent,
        IncidentsLuminairesRoutesRoute4meComponent,
        IncidentsComponent,
        IncidentsTableComponent,
        ShortenFolioPipe,
        IncidentsMapComponent,
        NewRepairTaskComponent,
        RepairTasksTableComponent,
        RepairSurveyComponent,
        AdminComponent,
        ReportByUserComponent,
        TicketsComponent,
        TicketsTableComponent,
        EditTicketComponent,
        TicketTableComponent,
        TicketMapComponent,
        ChargedValuesComponent,
        CategoryComponent,
        InventoryComponent,
        InventoryCategoryComponent,
        CartComponent,
        RequisitionsComponent,
        PendingComponent,
        SentComponent,
        HistoryComponent,
        MaterialsTableComponent,
        RepairsComponent,
        EditRepairComponent,
        RecoveredMaterialsComponent,
        RecoveredMaterialCategoryComponent,
        VersionsComponent,
        VersionComponent,
        AdministerPowerComponent,
        AssignPowerComponent,
        NewInstallationTaskComponent,
        InstallationSurveyComponent,
        InstallationComponent,
        LuminairesPendingInstallationComponent,
        LuminairesPendingTableComponent,
        LuminairesPendingMapComponent,
        SummaryComponent,
        KpiQuickTaskComponent,
        KpiInfrastructureTaskComponent,
        KpiInstallationTaskComponent,
        TicketsStatsComponent,
        RepairTasksPhaseComponent,
        RepairsPhaseComponent,
        LogMaintanenceComponent,
        CfeFilesImportComponent,
        UsersActiveTasksComponent,
        TarrifsComponent,
        ImportDetailComponent,
        RpusComponent,
        UserEfficiencyComponent,
        IndividualLogComponent,
        RpuDetailComponent,
        DashboardCfeComponent,
        AlertComponent,
        InstallationsComponent,
        MovementsLogComponent,
        OperationDashboardComponent,
        AlertsTypeComponent,
        IndividualDashboardComponent,
        TrackMaterialComponent,
        RequestMaterialComponent,
        ExtractedMaterialComponent,
        TrackedMaterialComponent,
        MaterialReqComponent,
        GuarantyClaimsComponent,
        GuarantyReportComponent,
        JudmentComponent,
        ReportRpuComponent,
        DetailGuarantyReportComponent,
        GuarantyluminaireComponent,
        GuarantyphotocellsComponent,
        GuarantyOpinionComponent,
        AssignatedOpinionComponent,
        CallCenterDashboardComponent,
        CountDowmComponent,
        LuminairesStatusComponent,
        LuminaireStatusComponent,
        GeneralViewProgressComponent,
        GeneralViewStatusComponent,
        GeneralViewWattageComponent,
        VirtualCircuitsComponent,
        AddCircuitComponent,
        LuminairesVirtualCircuitComponent,
        SchedulesComponent,
        GroupsComponent,
        DevicesComponent,
        DeviceDetailsComponent,
        DevicesTableComponent,
        DevicesMapComponent,
        VirtualCircuitsComponent,
        DeviceGraphicsComponent,
        DeviceCalendarComponent,
        DeviceGraphicsRssiComponent,
        GroupDetailsComponent,
        DeviceSimComponent,
        SmartAlertsComponent,
        SmartAlertsDetailsComponent,
        SmartDashboardComponent,
        DeviceKwhsComponent,
        TroopRecoveryItemsComponent,
        TroopMaterialItemsComponent,
        MarkerColorComponent,
        DeviceSensorsComponent,
        ConfimationStatusComponent,
        SectoresComponent,
        CensosComponent,
        RepairTasksComponent,
        StatusComponent,
        CategoryAlertsComponent,
        AlertsByTypeComponent,
        TableauDashboardComponent,
        TableauDashboardViewComponent,
        TableauDashboardIpadViewComponent,
        KpisComponent
    ],
    imports: [
        BrowserModule,
        FusionChartsModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            toastComponent: ToastrComponent
        }),
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CookieModule.forRoot(),
        ChartsModule,
        NgxMaskModule.forRoot(),
        AutocompleteLibModule,
        NgxMaterialTimepickerModule,
        NgSelectModule,
        // AngularFireDatabaseModule,
        // AngularFireAuthModule,
        // AngularFireMessagingModule,
        // AngularFireModule.initializeApp(environment.firebase),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthService, 
        AuthGuard,
        ProjectGuard,
        RoleGuard,
        ProjectService,
        NgxSpinnerService,
        Toastr,
        TranslateStore,
        CurrentProjectService,
        SiNoPipe,
        getReference_idLuminairesPipe,
        getCircuitsWithOutreferencePipe,
        getTicketsPipe,
        // MessagingService,
        // AsyncPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DashboardHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationHeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProjectHeaderInterceptor,
            multi: true
        }
    ],
    entryComponents: [ToastrComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
