// marker.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  private markersClearedSubject = new Subject<void>();

  markersCleared$ = this.markersClearedSubject.asObservable();

  clearMarkers() {
    this.markersClearedSubject.next();
  }
}
