import { Component, OnInit } from '@angular/core';
import { RepairsService } from '../repairs.service';
import { ActivatedRoute } from '@angular/router';
import { Repair } from 'app/models/repair';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiagnosisEnum } from 'app/models/diagnosis-enum';
import { StatusEnum } from 'app/models/status-enum';
import { WorkDoneEnum } from 'app/models/work-done-enum';
import { RepairProblemEnum } from 'app/models/repair-problem-enum';
import { RepairService } from './repair.service';
import { AuthService } from '@shared/auth/auth.service';

@Component({
  selector: 'app-edit-repair',
  templateUrl: './edit-repair.component.html'
})
export class EditRepairComponent implements OnInit {
  isClient : Boolean;
  diagnosisEnum = Object.keys(DiagnosisEnum).filter(key => !isNaN(Number(DiagnosisEnum[key])));
  statusEnum = Object.keys(StatusEnum).filter(key => !isNaN(Number(StatusEnum[key])));
  workDoneEnum = Object.keys(WorkDoneEnum)
  .filter(key => !isNaN(Number(WorkDoneEnum[key])))
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[0])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[1])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[6])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[7])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[9])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[11])  
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[14])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[15])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[16])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[17])
  .filter(key => WorkDoneEnum[key] !== WorkDoneEnum[18])
  
  problemEnum = Object.keys(RepairProblemEnum).filter(key => !isNaN(Number(RepairProblemEnum[key])));
  repair: Repair;
  repairForm: FormGroup;
  first_problem_image: any;
  second_problem_image: any;
  first_material_image: any;
  second_material_image: any;
  third_material_image: any;
  fourth_material_image: any;
  first_repair_image: any;
  second_repair_image: any;
  is_next = false;
  id_repairNext: string;
  id_Repair: string;

  constructor(
    private authService: AuthService,
    private repairsService: RepairsService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastr: Toastr,
    private repiarService: RepairService
  ) {
    this.isClient = this.authService.isClient();
    this.repairForm = this.fb.group(
      {
        id: [{ value: null, disabled: true }, Validators.required],
        diagnosis: [{ value: null }, Validators.required],
        status: [{ value: null}, Validators.required],
        work_done: [{ value: null }, Validators.required],
        problem: [{ value: null }, Validators.required],
        comment: [{ value: null }, Validators.required]
      }
    );
  }

  ngOnInit() {
      this.id_Repair = this.activatedRoute.snapshot.paramMap.get('id');
      this.repairsService.getRepair(this.id_Repair)
        .subscribe(
          (repair: Repair) => {
            this.repair = repair;
            this.setForm(this.repair);
          },
          (error) => {
            console.log(error);
            this.toastr.error(error);
          }
        )
  }

  setValueNew() {
    this.repairsService.getRepair(this.id_repairNext)
    .subscribe(
      (repair: Repair) => {
        this.repair = repair;
        this.setForm(this.repair);
        this.id_Repair = this.id_repairNext;
      },
      (error) => {
        this.toastr.error(error);
      }
    )
  }
  getActionArrow(is_next: string) {
    this.repiarService.nextRepair(this.id_Repair, is_next).subscribe(
      response => {
          this.id_repairNext = response;
          this.is_next = true;
          this.setValueNew();
      }
    );
  }


  setForm(repair: Repair) {
    console.log(repair.id);
    this.repairForm.patchValue({
      id: repair.id,
      diagnosis: repair.diagnosis,
      status: repair.status,
      work_done: repair.work_done,
      problem: repair.problem,
      comment: repair.comment
    });

    if(this.isClient){
      console.log('hey');
      this.repairForm.get('comment').disable();
      this.repairForm.get('work_done').disable();
      this.repairForm.get('diagnosis').disable();
      this.repairForm.get('status').disable();
      this.repairForm.get('problem').disable();
    }
  }

  setImagePreview(event: any, typeImage: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.repair[typeImage + '_url'] = reader.result.toString();
        this[typeImage] = file;
        this.saveRepair();
      };
      reader.readAsDataURL(file);
    }
  }

  private saveRepair() {
    let formData = new FormData();
    formData.append('repair[first_problem_image]', this.first_problem_image);
    formData.append('repair[second_problem_image]', this.second_problem_image);
    formData.append('repair[first_material_image]', this.first_material_image);
    formData.append('repair[second_material_image]', this.second_material_image);
    formData.append('repair[third_material_image]', this.third_material_image);
    formData.append('repair[fourth_material_image]', this.fourth_material_image);
    formData.append('repair[first_repair_image]', this.first_repair_image);
    formData.append('repair[second_repair_image]', this.second_repair_image);

    this.repairsService.updateRepair(this.repair.id, formData)
      .subscribe(result => {
        this.toastr.success('Reparación actualizada.');
      }, error => {
        this.toastr.error(error);
      });
  }

  onSaveRepair() {
    const form = {
      repair_id: this.repairForm.get('id').value,
      comments: this.repairForm.get('comment').value,
      work_done: this.repairForm.get('work_done').value,
      diagnosis: this.repairForm.get('diagnosis').value,
      status: this.repairForm.get('status').value,
      problem: this.repairForm.get('problem').value,
    }

    this.repiarService.editReapir(form).subscribe(result => {
      this.toastr.success('Reparación actualizada')
    }, error => {
      this.toastr.error('Erro al actualizar la reparación');
    })
  }
}
