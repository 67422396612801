import { DeviceMarkers, SimpleSmartGroup, SmartDeviceGeneralDetails, TicketPerDevice } from './../../../../models/smart-devices';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device, GetDevices } from '@app/models/devices';
import { Luminaire } from '@app/models/luminaire';
import { StyleMap } from '@app/models/styleMap';
import { GeneralViewLumWattage, GeneralViewWattage } from '@app/pages/general-view/general-view-project-wattages';
import { MapFuncAuxService } from '@app/shared/components/map-func-aux';
import { CurrentProjectService } from '@app/shared/cookies/current-project.service';
import { Toastr } from '@app/shared/toastr/toastr.service';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';
import { DevicesService } from '../devices.service';
import { LuminaireEnum } from '@app/models/luminaire-enum';
import { TechnologyEnum } from '@app/models/technology-enum';
import { GroupsService } from '../../groups/groups.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
  providers: [MapFuncAuxService, CurrentProjectService]
})
export class DeviceDetailsComponent implements OnInit {
  @ViewChild('modalInformation') deleteCircuitModal: any;
  @ViewChild('modalInfoDisableDevice') disableDeviceModal: any;

  @ViewChild('modalFixedTime') fixedTimeModal: any;
  luminaireEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));

  map: google.maps.Map;
  @ViewChild('gmap') gmapElement: any;

  fixedForm: FormGroup;
  // graphics_30days_values: any[] = [];
  device: SmartDeviceGeneralDetails;
  markers: DeviceMarkers[];
  simpleSmartGroup: SimpleSmartGroup[];
  tickets: TicketPerDevice[];
  styleMap: StyleMap = new StyleMap();
  Wattages: GeneralViewWattage[];
  lstInfowindows = [];
  is_detail_consulting = false;
  circuit_id: string;
  SchedulesList: [];

  constructor(
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService,
    private devicesService: DevicesService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private toaster: Toastr,
    private groupService: GroupsService,
    private fb: FormBuilder,
  ) {
    this.fixedForm = this.fb.group({
      onTime: [null],
      offTime: [null]
    })
  }




  ngOnInit(): void {
    this.circuit_id = this.activatedRoute.snapshot.paramMap.get('id');
    this.paintMap();
    this.getSmartGroups();
    this.getDeviceInfo();

    this.groupService.getSchedulesList().subscribe(res => {
      this.SchedulesList = res;

    })
  }




  get_detail_luminaire(id: string) {
    this.router.navigate(['/u/luminaires/' + id]);
  }

  clickTicket(event) {
    this.router.navigate(['/u/call-center/tickets/' + event.id]);
  }

  // u/luminaires/aa8c84e9-5c76-4b7d-a418-dc5e3bf0e92d
  onSelectGroup() {
    this.devicesService.updateDeviceGroup(this.activatedRoute.snapshot.paramMap.get('id'), this.device.group_id).subscribe(response => {
      this.toaster.success('Dispositivo actualizado')
    }, error => {
      this.toaster.singleError('Error Server')
    })
  }

  onClose() {
    this.modalService.dismissAll();
  }

  onSelectSchedule() {
    
    if (this.device.schedule_id != 'fijo') {
      this.devicesService.updateDeviceSchedule(this.activatedRoute.snapshot.paramMap.get('id'), this.device.schedule_id).subscribe(response => {
        this.toaster.success('Dispositivo actualizado');
      })
    } else {
      this.modalService.open(this.fixedTimeModal, { size: 'lg', backdrop: 'static', keyboard: false});
    }

  }

  private getSmartGroups() {
    this.devicesService.getSimpleSmartGroups().subscribe(response => {
      this.simpleSmartGroup = response;
    });
  }


  saveSchedule() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.devicesService.setfixedTime(params.get('id'), this.fixedForm.get("onTime").value, this.fixedForm.get("offTime").value).subscribe(() => {
        this.toaster.success("Horario asignado");
        this.modalService.dismissAll();
      }, () => {
        this.toaster.singleError("Error Server");
      })
    })
    
  }


  getDeviceInfo() {
    this.activatedRoute.paramMap.subscribe(params => {

      if (!params.has('id')) {
        this.toaster.singleError('URL Error');
        return;
      }

      this.devicesService.getDeviceDetails(params.get('id')).subscribe(device => {
        const markers = device.markers.map(marker => new DeviceMarkers(marker));
        this.tickets = device.tickets;
        this.Wattages = device.wattages.map(wattage => new GeneralViewWattage(wattage));
        let deviceDetails = new SmartDeviceGeneralDetails(device);
        this.device = deviceDetails;

        this.fixedForm.patchValue({
          onTime: deviceDetails.on_time,
          offTime: deviceDetails.off_time
        });

        markers.forEach(marker => {
          marker.paintMarker(this.map);
          if (marker.element_type === 'luminaire') {
            // this.markers.push(marker);
            marker.marker.addListener('click', () => {
              this.showInfoMarker(marker);
            });
          }
          if (marker.element_type === 'circuit') {
            this.map.setCenter(new google.maps.LatLng(marker.location.latitude, marker.location.longitude));
            this.map.setZoom(16);
          }
        });

        this.markers = markers.filter(element => element.element_type === 'luminaire');
        
      }, error => {
        this.toaster.singleError('Server Error');
      })
    })
  }

  deleteCircuit() {
    this.modalService.open(this.deleteCircuitModal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
        // if (result === true) {

        // }
        // console.log(result);
      },
      reason => {
        if (reason === true) {
          this.devicesService.deleteCircuitDevice(this.circuit_id).subscribe(response => {
            if (response === true) {
              this.router.navigate(['/u/smart/devices']);
            } else {
              this.toaster.singleError(response.toString());
            }
          }, error => {
            this.toaster.error(error);
          });
        }

        console.log(reason);
      });
  }

  disableDevice() {
    this.modalService .open(this.disableDeviceModal, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
      result => {
        // if (result === true) {

        // }
        // console.log(result);
      },
      reason => {
        if (reason === true) {
          let deviceID = (this.activatedRoute.snapshot.paramMap.get('id'));

          this.devicesService.disableDevice(deviceID).subscribe(response => {
            if (response === true) {
              this.router.navigate(['/u/smart/devices']);
            } else {
              this.toaster.singleError(response.toString());
            }
          }, error => {
            this.toaster.error(error);
          });
        }

        console.log(reason);
      });
  }

  private showInfoMarker(marker: DeviceMarkers) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = this.getStringInfo(marker);
    let infowindow = new google.maps.InfoWindow({ content: strInfo });
    infowindow.open(this.map, marker.marker);
    window.setTimeout(() => {
      const seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(marker.element_id));
    }, 500);
    this.lstInfowindows.push(infowindow);

  }

  private getStringInfo(luminaire: DeviceMarkers): string {
    return `
    <div class="container my-0 mx-0 py-0 px-0">
      <div class="row mx-1 my-0 mt-1">
        <div class="col-6">
          <div class="row">
            <div class="col-12 px-0">
              <strong>${luminaire.element_id ? 'Referencia' : 'ID'}</strong>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 px-0">${luminaire.element_reference || luminaire.element_id}</div>
          </div>
          <div class="row">
            <div class="col-12 px-0">
              <strong>Tipo</strong>
            </div>
          </div>
          <div class="row mb-1">
            ${this.luminaireEnum[luminaire.type]}
          </div>


        </div>
        <div class="col-6">

        <div class="row">
        <div class="col-12 px-0"><strong>Tecnología</strong></div>
      </div>
      <div class="row mb-1">
        ${this.technologyEnum[luminaire.technology]}
      </div>

        <div class="row">
        <div class="col-12 px-0"><strong>Fotocelda</strong></div>
      </div>
      <div class="row mb-1">
        ${luminaire.has_photocell ? 'Si' : 'No'}
      </div>

          <div class="row">
            <div class="col-12 px-0">
              <a href="u/luminaires/${luminaire.element_id}" onclick="return false;">
                <input id="seeMoreLuminaire" type="button" style="position: absolute; cursor:pointer;" value="Ver mas" class="oe--button--info">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }


  paintMap() {
    const latitude = 19.4326009;
    const longitude = -99.1333416;
    const zoom = 5;
    const project = this.currentProjectService.getCurrentProject()
    const mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    const service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
  }

  activeDevice(event) {
    const form = {
      device_id: this.activatedRoute.snapshot.paramMap.get('id'),
      command: event,
    }
    this.devicesService.ActiveMeter(this.device.device_name.replace('G', ''), event).subscribe(res => {
      if (parseInt(res['status']['code']) == 200) {
        this.devicesService.addAnnotation(form).subscribe(res => {

        });
        this.toaster.success(`Se ${event === true ? 'Encendio' : 'Apago'} correctamente`);
      } else {
        this.toaster.singleError('El dispositivo no se encendio. Intente nuevamente')
      }
    }, error => {
      this.toaster.error('Error Server');
    });
  }
  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

}
