
interface ITicket {
    id: string,
    reference: string,
    citizen_name: string,
    citizen_last_name: string,
    total_luminaires: string,
    status: string,
    source:  number,
    finished_at: string,
    created_at: string
  }
  
  export class Ticket implements ITicket {
    id: string
    reference: string
    citizen_name: string
    citizen_last_name: string
    total_luminaires: string
    status: string
    source: number
    finished_at: string
    created_at: string

    constructor(attrs) {
      this.id = attrs.id;
      this.reference = attrs.reference;
      this.citizen_name = attrs.citizen_name;
      this.citizen_last_name = attrs.citizen_last_name;
      this.total_luminaires = attrs.total_luminaires;
      this.status = attrs.status;
      this.source = attrs.source;
      this.finished_at = attrs.finished_at;
      this.created_at = attrs.created_at;
    }
}

