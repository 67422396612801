
export class lumProgress {
  luminaires: LuminaireNew[];
  progress: Progress[];

  constructor(attrs){
    this.luminaires = attrs.luminaires;
    this.progress = attrs.progress;
  }
}

export class Progress {
  key: number;
  count: number;
  wattKey: string;

  constructor(attrs){
    this.key = attrs.key;
    this.count = attrs.count;
    this.wattKey = attrs.wattKey;
  }
}

interface LuminaireNewInterface {
  id: string,
  reference_id: string;
  municipality_status: number;
  location: {
    latitude: number,
    longitude: number
  }
  type: number;
  technology: number;
  has_photocell: boolean;
  progress: number;
  comment: string;
  selectedTab: number;
  curcuit_id: string;
}

export class LuminaireNew implements LuminaireNewInterface {
    id: string;
    reference_id: string;
    municipality_status: any;
    location: Location
    event: string;
    linkedSituation: string;
    marker: google.maps.Marker;
    isSelected: Boolean;
    curcuit_id: string;
    type: number;
    technology: number;
    has_photocell: boolean;
    progress: number;
    comment: string;
    selectedTab: number;
    progressIconEnum: string
    

  constructor(attrs, linkedSituation: string = 'single', event: string = 'normal') {
    this.id = attrs.id;
    this.reference_id = attrs.reference_id;
    this.municipality_status = attrs.municipality_status;
    this.location = new Location(attrs.location); 
    this.linkedSituation = linkedSituation;
    this.event = event;
    this.isSelected = false;
    this.curcuit_id = attrs.curcuit_id;
    this.type = attrs.type;
    this.technology = attrs.technology;
    this.has_photocell = attrs.has_photocell;
    this.progress = attrs.progress;
    this.comment = attrs.comment;
    this.selectedTab = attrs.selectedTab;
    this.progressIconEnum = this.progress != null ? ProgressIconrEnum[this.progress] : null;
  }

  getMarkerIcon() {
    let  result = {}
    if (this.selectedTab === 0) {
      result = {
        linked: {
          hover: 'assets/img/svg/ic_link_hover.svg',
          normal: 'assets/img/svg/link.svg',
          selected: 'assets/img/svg/lamppost_selected-0.svg',
          circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        },
        single: {
          normal: `assets/img/svg/ic_municipality_status-`,
          selected: 'assets/img/svg/ic_municipality_selected-status-'
        },
        assign: {
          selected: 'assets/img/svg/ic_municipality_selected-status-',
          circuitShowing: 'assets/img/svg/ic_install_linked-',
          normal: 'assets/img/svg/ic_municipality_status-',
          hover: 'assets/img/svg/ic_municipality_hover_status-',
        }
      }
    }
    if (this.selectedTab === 1) {
      result = {
        linked: {
          hover: 'assets/img/svg/ic_link_hover.svg',
          normal: 'assets/img/svg/link.svg',
          selected: 'assets/img/svg/lamppost_selected-0.svg',
          circuitShowing: 'assets/img/svg/ic_link_circuit.svg'
        },
        single: {
          normal: `assets/img/svg/ic_install_pin_generalView-`,
          selected: 'assets/img/svg/ic_install_pin_generalView-'
        },
        assign: {
          selected: 'assets/img/svg/ic_install_selected-',
          circuitShowing: 'assets/img/svg/ic_install_linked-',
          normal: 'assets/img/svg/ic_install_pin-',
          hover: 'assets/img/svg/ic_install_hover-',
        }
      }
    }

return result;
  }
  get_icon(): object {
    let tab = {};
    if (this.selectedTab === 0) {
      tab = this.linkedSituation === 'assign'
    ?
      { url: `${this.getMarkerIcon()['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.municipality_status}.svg`,
      scaledSize: new google.maps.Size(12, 12) }
    :
      {
           url: `${this.getMarkerIcon()[this.linkedSituation][this.event === 'normal' ? this.isSelected ?
        'selected' : 'normal' : this.event]}${this.municipality_status}.svg`,
      scaledSize: new google.maps.Size(10, 10)
      };
    }
    if (this.selectedTab === 1) {
      tab = this.linkedSituation === 'assign'
    ?
      { url: `${this.getMarkerIcon()['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
      scaledSize: new google.maps.Size(12, 12) }
    :
      {
           url: `${this.getMarkerIcon()[this.linkedSituation][this.event === 'normal' ? this.isSelected ?
        'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
      scaledSize: new google.maps.Size(10, 10)
      };
    }
    return  tab;
  }
  
  get_iconForSearch(): object {
   return this.linkedSituation === 'assign'
    ?
      { url: `${this.getMarkerIcon()['assign'][this.event === 'normal' ? this.isSelected ? 'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
      scaledSize: new google.maps.Size(20, 20) }
    :
      {
           url: `${this.getMarkerIcon()[this.linkedSituation][this.event === 'normal' ? this.isSelected ?
        'selected' : 'normal' : this.event]}${this.progressIconEnum}.svg`,
      scaledSize: new google.maps.Size(20, 20)
      };
    }
  

  upadeIcon(event: string): void {
    this.event = event;
    this.marker.setIcon(this.get_icon());
  }

  paintMarker(map: any): void {
      const latitude = this.location.latitude;
      const longitude = this.location.longitude;
      this.event = this.isSelected ? 'selected' : 'normal';
      this.marker = new google.maps.Marker({
          title: this.reference_id == null ? this.id : this.reference_id.toString(),
          position: new google.maps.LatLng(latitude, longitude),
          icon: this.get_icon(),
          map: map,
         
      }); 
  }

  hideMarker(): void {
    this.marker.setMap(null);
  }

  setMap(map: any): void {
    this.marker.setMap(map);
  }
}

interface LocationInterface {
  latitude: number;
  longitude: number;
}
export class Location implements LocationInterface {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
      this.latitude = attrs.latitude;
      this.longitude = attrs.longitude;
  }
}

export enum ProgressEnum {
  'Censo rápido',
  'Censo infraestructura',
  'Instalación',
}

export enum ProgressIconrEnum {
  '485_C',
  '190_C',
  '139_C',
}
