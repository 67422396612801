// kpis.component.ts
import { Component, OnInit } from '@angular/core';
import { IncidentsService } from '../incidents.service'; // Ajusta la ruta si es necesario

@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.scss']
})
export class KpisComponent implements OnInit {
  statusLuminaires: any;

  constructor(private incidentsService: IncidentsService) {}

  ngOnInit(): void {
    this.incidentsService.getStatusLuminaires().subscribe(data => {
      this.statusLuminaires = data;
    });
  }
}
