import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { GetTicketResponse } from '@models/get-ticket-response';
import { CitizenPayload } from '@models/citizen-payload';
import { GenericTable } from '@app/models/generic-table';
import { map } from 'rxjs/operators';
import { Ticket } from './ticket';
import { TicketInformation } from './edit-ticket/ticket';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  isClient : Boolean;
  constructor(
    private httpClient: HttpClient, 
    private authService: AuthService,
    ) { 
      this.isClient = this.authService.isClient();
    }

    getTickets(form, page) {
      form.size = 5;
      if (this.isClient) {
        if (form.phone_number && !form.phone_number.startsWith('+521')) {
          form.phone_number = '+521' + form.phone_number.replace(/[^0-9]/g, ''); // Solo permite números
        }
        const result = this.handleClientFilters(form);
        if (result) return result; // Si retorna algo, lo usamos
      }
    
      const httpParams = new HttpParams().set('page', page);
      return this.httpClient.post<GenericTable<Ticket>>(
        `${environment.SERVER_CALLCENTER}/api/Tickets/GetTickets`, 
        form, 
        { params: httpParams }
      ).pipe(
        map(elements => new GenericTable<Ticket>(elements))
      );
    }
    
    /**
     * Maneja la lógica específica para clientes
     */
    private handleClientFilters(form) {
      form.limit = 5;
      if (!form.finished_at) {
        form.start_date = { 
          day: 3, 
          month: 10, 
          year: 2024 
        };
      }
    
      if (!form.phone_number && !form.citizen_name && !form.reference) {
        return of(new GenericTable<Ticket>({
          count: 1,
          elements: [this.createEmptyTicket()], 
          from: 0,
          index: 0,
          next: 0,
          previous: 0,
          size: 10
        }));
      }
    
      return null; 
    }
    
    /**
     * Genera un ticket vacío
     */
    private createEmptyTicket(): Ticket {
      return new Ticket({
        id: '',
        reference: '',
        citizen_name: '',
        citizen_last_name: '',
        total_luminaires: '',
        status: '',
        source: '',
        finished_at: '',
        created_at: ''
      });
    }
    

  getTicketByIdOff(id: string): Observable<GetTicketResponse> {
    return this.httpClient
      .get<GetTicketResponse>(`${environment.SERVER_URL}/tickets/${id}`);
  }

  getTicketById(id) {
    return this.httpClient
      .get<TicketInformation>(`${environment.SERVER_CALLCENTER}/api/Tickets/get-ticket?ticket_id=${id}`)
      .pipe(
        map(elements => new TicketInformation(elements))
      );
  }

  editTicketOff(citizenPayload: CitizenPayload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.SERVER_URL}/citizens/${citizenPayload.id}`,
      citizenPayload.toJson()
    );
  }

  editTicket_(values: any) {
    return this.httpClient.post(
      `${environment.SERVER_CALLCENTER}/api/Tickets/update-citizen-ticket`, values
    );
  }

  ExportXLSXDetailTickect(ticketsSearchForm: FormGroup) {
    // const httpParams = new HttpParams()
    // .set('reference', ticketsSearchForm.get('reference').value == null ? '' : ticketsSearchForm.get('reference').value)
    // .set('citizen_name', ticketsSearchForm.get('citizen_name').value == null ? '' : ticketsSearchForm.get('citizen_name').value)
    // .set('startDate', ticketsSearchForm.get('start_date').value)
    // .set('status', ticketsSearchForm.get('status').value )
    // .set('endDate', ticketsSearchForm.get('end_date').value);

    this.httpClient.post(
      `${environment.SERVER_CALLCENTER}/api/Tickets/export-detail-tickets`, ticketsSearchForm, { responseType: 'blob' }).subscribe(file => {
        this.downloadProcess(file, `reporte-tickets.xlsx`);
      });
  }

  private downloadProcess(file, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([file], { type: 'application/vnd.ms-excel' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

}
