import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ReportedElements } from 'app/models/reported_elements';
import { StyleMap } from 'app/models/styleMap';
import { CurrentProjectService } from 'app/shared/cookies/current-project.service';
import { MapFuncAuxService } from 'app/shared/components/map-func-aux';
import { Router } from '@angular/router';
import { Toastr } from 'app/shared/toastr/toastr.service';
import { VehiclesLocation } from '@app/models/vehicles-location';

@Component({
  selector: 'app-incidents-map',
  templateUrl: './incidents-map.component.html',
  styleUrls: ['./incidents-map.component.scss'],
  providers: [CurrentProjectService, MapFuncAuxService]
})
export class IncidentsMapComponent implements OnInit, OnChanges {
  @Input() reported_elements: ReportedElements;
  @Input() vehicles: VehiclesLocation[];
  @ViewChild('gmap') gmapElement: any;
  @Input() selectedCircuit: any;
  @Output() markersCleared = new EventEmitter<void>();
  map: google.maps.Map;
  styleMap: StyleMap = new StyleMap();
  showMap = false;
  vehiclesVisible: boolean = true;
  lstInfowindows = [];
  lstCircuitsMarker: google.maps.Marker[] = [];

  constructor(
    private toastr: Toastr,
    private router: Router,
    private mapFuncAux: MapFuncAuxService,
    private currentProjectService: CurrentProjectService
  ) { }

  ngOnInit() {
    this.paintMap();
    this.paintvehicles();
    this.paintCircuits();
    this.paintLampposts();
    this.paintLuminaires();
    
    if (this.selectedCircuit) {
      this.selectedCircuit.marker.setAnimation(google.maps.Animation.BOUNCE);
      this.selectedCircuit = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vehicles && this.vehicles) {
      this.paintvehicles();
    }
  }

  paintMap(){
    let latitude = 19.4326009;
    let longitude = -99.1333416;
    let zoom = 5;
    let project = this.currentProjectService.getCurrentProject()
    let mapProperties = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let request = {
      query: `${project.municipality}, ${project.state}, ${project.country}`,
      fields: ['name', 'geometry'],
    };


    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProperties);
    let service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })

    this.map.addListener('click', () => {
      this.hideCircuit();
      this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    });

  }

  paintCircuits() {
    for (let circuit of this.reported_elements.circuits){
      circuit.paintMarker(this.map);
      circuit.marker.addListener('click', () => {
        this.showCircuit(circuit);
        this.showInfoWindowCir(circuit);
        circuit.marker.setAnimation(null);
      });
      for (let lamppost of circuit.lampposts){
        for (let luminaire of lamppost.luminaires){
          luminaire.paintMarker(this.map);
          luminaire.marker.addListener('click', () => {
            this.showCircuit(circuit);
            this.showInfoWindowLum(luminaire);
          });
          luminaire.marker.addListener('mouseover', () => {
            lamppost.event = 'hover';
            lamppost.upadeIcon();
          });
          luminaire.marker.addListener('mouseout', () => {
            lamppost.event = circuit.event;
            lamppost.upadeIcon();
          });
        }
      }
    }
  }

  paintLampposts() {
    for (let lamppost of this.reported_elements.lampposts){
      for (let luminaire of lamppost.luminaires){
        luminaire.paintMarker(this.map);
        luminaire.marker.addListener('click', () => {
          this.hideCircuit();
          this.showInfoWindowLum(luminaire);
        });
        luminaire.marker.addListener('mouseover', () => {
          lamppost.event = 'hover';
          lamppost.upadeIcon();
        });
        luminaire.marker.addListener('mouseout', () => {
          lamppost.event = 'normal';
          lamppost.upadeIcon();
        });
      }
    }
  }

  paintLuminaires() {
    for (let luminaire of this.reported_elements.luminaires){
      luminaire.paintMarker(this.map);
      luminaire.marker.addListener('click', () => {
        this.hideCircuit();
        this.showInfoWindowLum(luminaire);
      });
    }
  }
  
  paintvehicles(): void {
    if (this.vehicles) {
      this.vehicles.forEach(vehicle => {
        if (!vehicle.marker) {
          vehicle.paintMarker(this.map);
          vehicle.marker.setMap(this.vehiclesVisible ? this.map : null);
          vehicle.marker.addListener('click', () => {
            this.hideCircuit();
            this.showInfoWindowVechiel(vehicle);
          });
        } else {
          vehicle.marker.setMap(this.vehiclesVisible ? this.map : null);
        }
      });

      // Emitir el evento después de pintar los vehículos
      this.markersCleared.emit();
    }
  }
  
  
  
  private circuitSelect(circuit: any): void {
    this.hideCircuit();
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    circuit.isSelected = !circuit.isSelected;
    circuit.event = 'normal';
    for (let lamppost of circuit.lampposts){
      for (let luminaire of lamppost.luminaires){
        luminaire.event = 'normal';
        luminaire.isSelected = circuit.isSelected;
      }
    }
    circuit.upadeIcon();
  }

  private checkCircuitStatus(circuit: any) {
    let status = false;
    for (let lamppost of circuit.lampposts){
      if (status)
        break;
      for (let luminaire of lamppost.luminaires){
        if (luminaire.status === 1 || luminaire.status === 3 || luminaire.status === 4) {
          status = true;
          break;
        }
      }
    }
    if (status) {
      this.toastr.singleError('Existen luminarias que ya están en proceso de reparación.');
    } else {
      this.circuitSelect(circuit);
    }
  }

  private checkStatus(luminaire: any) {
    if (luminaire.status === 0 || luminaire.status === 2) {
      this.luminaireSelect(luminaire);
    } else {
      this.toastr.singleError('La luminaria ya se encuentra en poceso de reparación.');
    }
  }

  private luminaireSelect(luminaire: any): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    let circuit = this.hasCircuit(luminaire);
    if (circuit) {
      circuit.isSelected = false;
      circuit.event = 'normal';
      circuit.upadeIcon();
    } else {

    }
    luminaire.isSelected = !luminaire.isSelected;
    luminaire.event = 'normal';
    luminaire.upadeIcon();
  }

  toggleVehicles(): void {
    this.vehiclesVisible = !this.vehiclesVisible;
    this.vehicles.forEach(vehicle => {
      if (this.vehiclesVisible) {
        vehicle.marker.setMap(this.map); // Mostrar vehículos
      } else {
        vehicle.marker.setMap(null); // Ocultar vehículos
      }
    });
  }

  private showInfoWindowLum(luminaire: any) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    let strInfo = `
    <div>
      <div>
        <p class="mb-0"> <strong> Estado: </strong> </p>
      </div>
      <div>
        <p>${luminaire.statusName}</p>
      </div>
      ${luminaire.problems.length ? `
      <div>
        <p class="mb-0"> <strong> Fallas: </strong> </p>
      </div>
      <div>
        <p>${luminaire.problemsNames.join(', ')}</p>
      </div>
      `
      : '' }
      <div class="d-flex justify-content-between">
        <div class="mr-1">
          <a href="u/luminaires/${ luminaire.id }" onclick="return false;">
            <input id="seeMoreLuminaire" type="button" style=" cursor:pointer;" value="Ver mas" class="oe--button--info">
          </a>
        </div>
        <div>
          ${luminaire.isSelected
            ? '<input id="btnDesselected" type="button" style="cursor:pointer;" value="Deseleccionar" class="oe--button--info">'
            : '<input id="btnSelected" type="button" style="cursor:pointer;" value="Seleccionar" class="oe--button--info ">'}
        </div>
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, luminaire.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSelected = document.getElementById('btnSelected');
      let btnDesSelected = document.getElementById('btnDesselected');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      if (btnSelected)
        btnSelected.addEventListener('click', () => this.checkStatus(luminaire));
      if (btnDesSelected)
        btnDesSelected.addEventListener('click', () => this.luminaireSelect(luminaire));
      seeMoreLuminaire.addEventListener('click', () => this.mapFuncAux.seeMoreLuminaire(luminaire.id));
    }, 500);
  }

  private showInfoWindowCir(circuit: any) {
    let strInfo = `
    <div>
      <div class="d-flex justify-content-between">
        <a href="u/circuits/${ circuit.id }" onclick="return false;">
          <input id="seeMoreLuminaire" type="button" style="cursor:pointer;" value="Ver mas" class="oe--button--info mr-1">
        </a>
        ${circuit.isSelected
          ? '<input id="btnDesselected" type="button" style="cursor:pointer;" value="Deseleccionar" class="oe--button--info">'
          : '<input id="btnSelected" type="button" style="cursor:pointer;" value="Seleccionar" class="oe--button--info">'}
      </div>
    </div>
    `;
    let infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340
    });
    infowindow.open(this.map, circuit.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      let btnSelected = document.getElementById('btnSelected');
      let btnDesSelected = document.getElementById('btnDesselected');
      let seeMoreLuminaire = document.getElementById('seeMoreLuminaire');
      if (btnSelected)
        btnSelected.addEventListener('click', () => this.checkCircuitStatus(circuit));
      if (btnDesSelected)
        btnDesSelected.addEventListener('click', () => this.circuitSelect(circuit));
        seeMoreLuminaire.addEventListener('click', () => this.router.navigate([`/u/circuits/${circuit.id}`]));
    }, 500);
  }

  private showCircuit(circuit: any): void {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    this.hideCircuit();
    circuit.event = 'circuitShowing';
    circuit.upadeIcon();
  }

  private hideCircuit(): void {
    let _circuit = this.reported_elements.circuits.find(__circuit => __circuit.event === 'circuitShowing');
    if (_circuit) {
      _circuit.event = _circuit.isSelected ? 'selected' : 'normal';
      _circuit.upadeIcon();
    }
  }

  private hasCircuit(luminaire: any): any {
    let _circuit;
    for (let circuit of this.reported_elements.circuits){
      for (let lamppost of circuit.lampposts){
        for (let __luminaire of lamppost.luminaires){
          if (__luminaire.id === luminaire.id) {
            _circuit = circuit;
            break;
          }
        }
      }
    }
    return _circuit;
  }

  changeTypeMap(value) {
    this.map.setOptions({
      styles: this.styleMap.styles[value]
    })
  }

  getPositionInMap() {
    let cuurentProject = this.currentProjectService.getCurrentProject();
    let service = new google.maps.places.PlacesService(this.map);
    let request = {
      query: `${cuurentProject.municipality}, ${cuurentProject.state}, ${cuurentProject.country}`,
      fields: ['name', 'geometry'],
    };
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.map.setZoom(12);
        this.map.setCenter(results[0].geometry.location);
      }
    })
  }
  private showInfoWindowVechiel(Vehicles: VehiclesLocation) {
    this.mapFuncAux.closeAllInfowindows(this.lstInfowindows);
    const strInfo = `
    <div>

      <div>
        <p class='mb-0'> <strong> No. Vehicle: </strong> </p>
      </div>
      <div>
        <p>${Vehicles.name}</p>
      </div>
      <div class='d-flex justify-content-between'>
        <div class='mr-1'>
          <a href='u/troops/${Vehicles.troop_id}' onclick='return false;'>
            <input id='seeMoreVehicles' type='button' style=' cursor:pointer;' value='Ver mas' class='oe--button--info'>
          </a>
        </div>
        <div>
        </div>
      </div>
    </div>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: strInfo,
      maxWidth: 340,
    });
    infowindow.open(this.map, Vehicles.marker);
    this.lstInfowindows.push(infowindow);
    window.setTimeout(() => {
      const btnSelected = document.getElementById('btnSelected');
      const seeMoreVehicles = document.getElementById('seeMoreVehicles');
      if (btnSelected) {
        btnSelected.addEventListener('click', () =>
          this.checkStatus(Vehicles)
        );
      }
      seeMoreVehicles.addEventListener('click', () =>
        this.mapFuncAux.seeMoreVehicles(Vehicles.troop_id)
      );
    }, 500);
  }
}
