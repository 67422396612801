import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TechnologyEnum } from '@models/technology-enum';
import { LuminaireEnum } from '@models/luminaire-enum';
import { ProblemEnum } from '@models/problem-enum';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncidentsService } from './incidents.service';
import { Toastr } from '@shared/toastr/toastr.service';
import { ReportedElementsResponse } from '@models/reported_elements-response';
import { ReportedElements } from '@models/reported_elements';
import { Luminaire } from '@models/luminaire';
import { AdminService } from '@pages/admin/admin.service';
import { MarkerService } from './marker.service';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { Project } from '@models/project';
import { Sector } from '@app/models/sector';
import { ReportedElementsRejectPayload } from './reported-elements-reject-payload';
import { AuthService } from '@app/shared/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { VehiclesLocation } from '@app/models/vehicles-location';
import { IncidentsLuminairesRoute4MeService } from './incidents-luminaires-route4me/incidents-luminaires-route4-me.service';


class SearchValues {
  q?: string
  type?: string
  technology?: string
  problem?: string
  status?: string
  start_day?: Date
  end_day?: Date
}

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  // template: `<app-incidents-luminaires-route4me [value]="this.list_total_luminaire_incidents"></app-incidents-luminaires-route4me>`,
  styleUrls: ['./incidents.component.scss'],
  providers: [IncidentsService]
})
export class IncidentsComponent implements OnInit, OnDestroy {
  technologyEnum = Object.keys(TechnologyEnum).filter(key => !isNaN(Number(TechnologyEnum[key])));
  luminariaTypeEnum = Object.keys(LuminaireEnum).filter(key => !isNaN(Number(LuminaireEnum[key])));
  problemEnum = Object.keys(ProblemEnum).filter(key => !isNaN(Number(ProblemEnum[key])));
  @ViewChild('modalAviso') modalAviso: any;
  @ViewChild('contentNotApplicable') modalNotApplicable: any;
  @ViewChild('customModal') createTaskModal: any;
  project: Project;
  currentPage = 1;
  // valuesForRouteForm: FormGroup;
  incidentsSearchForm: FormGroup;
  valueOfIncidentsSearch: SearchValues;
  reported_elements: ReportedElements;
  showMap = false;
  showNewReparationTask = false;
  selectedCircuit = null;
  projectLuminaires: Luminaire[] = [];
  showAddButtons = true;
  phaseNumber = 3;
  vehicles: VehiclesLocation[];
  automaticMethod = false;
  manualMethod = false;
  luminarias = { SinAsignar: 0, ProgramadaReparacion: 0, Pendiente: 0, EnProcesoReparacion: 0, Otro: 0, Funcionando: 0, Garantias: 0, ReparacionCircuito: 0, TotalRepair: 0 };
  porcentajeLuminariasFuncionando: number = 0;
  // active_save_create_route = false;
  list_total_luminaire_incidents = [];
  showTaskMethodType = false;
  automated_luminaires = 0;
  work_type: string;
  isLoading = false;
  sectors: Sector[] = [];
  filteredSectors: Sector[] = [];
  isLoadingSectors = false;
  sectorsLoaded = false;
  showAllSectors: boolean = false;
  selectedSectors: Sector[] = [];
  searchTerm: string = '';
  modalRef: any;
  private updateInterval: any

  statusOptions = [
    { value: 'all', label: 'Todos' },
    { value: '0', label: 'Sin asignar' },
    { value: '1', label: 'Programada para reparación' },
    { value: '2', label: 'Pendiente' },
    { value: '3', label: 'En proceso de reparación' },
    { value: '4', label: 'Funcionando' },
    { value: '5', label: 'Garantia' },
    { value: '6', label: 'Reparación de Circuito' }
  ];

  // luminaires_id: [{id: string}] = [{id: 'No cuenta'}];

  constructor(
    private router: Router,
    private toastr: Toastr,
    private fb: FormBuilder,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private incidentsService: IncidentsService,
    private markerService: MarkerService,
    private currentProjectService: CurrentProjectService,
    private incidentsRoute4MeService: IncidentsLuminairesRoute4MeService,
    private authService: AuthService
  ) {
    // this.valuesForRouteForm = fb.group({
    //   route_day: [''],
    //   start_date_: null,
    //   total_lums: 0,
    //   turn_mat_vesp: 0,
    //   newer_oldest: 0,
    //   luminaires_detail: []
    // });

    this.incidentsSearchForm = fb.group({
      q: [''],
      type: ['all'],
      technology: ['all'],
      problem: ['all'],
      status: ['all'],
      start_day: [''],
      end_day: ['']
    });
    this.filteredSectors = this.sectors;
  }

  ngOnInit() {
    this.setButtonVisibility();
    this.phaseNumber = this.currentProjectService.getCurrentProject().phase;
    this.route.queryParamMap.subscribe(queries => {
      const q = queries.get('q') ? queries.get('q') : '';
      const type = queries.get('type') ? queries.get('type') : 'all';
      const technology = queries.get('technology') ? queries.get('technology') : 'all';
      const problem = queries.get('problem') ? queries.get('problem') : 'all';
      const status = queries.get('status') ? queries.get('status') : 'all';
      const start_formDate = queries.get('start_day') ? queries.get('start_day') : '';
      const end_formDate = queries.get('end_day') ? queries.get('end_day') : '';
      let start_day, end_day;
      if (start_formDate) {
        const date = new Date(start_formDate)
        start_day = new NgbDate(date.getFullYear(), (date.getMonth() + 1), date.getDate());
      }
      if (end_formDate) {
        const date = new Date(end_formDate)
        end_day = new NgbDate(date.getFullYear(), (date.getMonth() + 1), date.getDate());
      }
      this.populateForm(q, type, technology, problem, status, start_day, end_day);
      this.getIncidents(q, type, technology, problem, status, start_formDate, end_formDate);
      this.getVehiclesLocation(true);

      if (this.updateInterval) {
      clearInterval(this.updateInterval);
      }

      this.updateInterval = setInterval(() => {
        this.getVehiclesLocation(true); // Llama sin recrear el mapa
      }, 180000);
  
    });

    $(this.modalAviso.nativeElement).on('hidden.bs.modal', () => {
      // Reset your values here
    });
  }

  ngOnDestroy(): void {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

  updateSelectedSectors(sector) {
    if (sector.checked) {
      this.selectedSectors.push(sector);
    } else {
      this.selectedSectors = this.selectedSectors.filter(s => s.id !== sector.id);
    }
  }

  toggleSectorList() {
    this.showAllSectors = !this.showAllSectors;
    // If we're showing all sectors, clear the search term
    if (this.showAllSectors) {
      this.searchTerm = '';
    }
  }

  filterSectors(event: any) {
    this.searchTerm = event.target.value.toLowerCase();
    this.filteredSectors = this.searchTerm
      ? this.sectors.filter(sector => sector.name.toLowerCase().includes(this.searchTerm))
      : this.sectors;
    this.showAllSectors = false; // Collapse the list when searching
  }

  setButtonVisibility() {
    const token = this.authService.getToken();
    const helper = new JwtHelperService();
    const decodeToken = helper.decodeToken(token);

    if (decodeToken["role_name_enums"][0] === 8) {
      this.showAddButtons = false;
    }
  }


  // private Reset_Form_Route() {
  //   this.active_save_create_route = false;
  //   this.valuesForRouteForm.patchValue({
  //     total_lums: 0,
  //     route_day: [''],
  //     start_date_: null,
  //     turn_mat_vesp: 0,
  //     newer_oldest: 0,
  //   });
  // }

  // ValidateSave() {
  //   if (Number(this.valuesForRouteForm.get('newer_oldest').value) > 0 && (this.valuesForRouteForm.get('start_date_').value != null && this.valuesForRouteForm.get('start_date_').value !== undefined) && Number(this.valuesForRouteForm.get('total_lums').value) > 0 && Number(this.valuesForRouteForm.get('turn_mat_vesp').value) > 0) {
  //     this.active_save_create_route = true;
  //   } else {
  //     this.active_save_create_route = false;
  //   }

  // }

  view_routes_luminaires() {
    this.router.navigate(['/u/call-center/incidents/incidents-luminaires-routes-route4me'],
      {
        relativeTo: this.route
      });
  }


  create_route_luminaires() {
    this.router.navigate(['/u/call-center/incidents/incidents-luminaires-route4me'],
      {
        relativeTo: this.route
      });
  }

  private addLuminaireIncidents(luminaire) {
    if (luminaire.status === 0 || luminaire.status === 2) {
      this.list_total_luminaire_incidents.push(luminaire);
    }
  }

  getIncidents(q: string, type: string, technology: string, problem: string, status: string, start_day: string, end_day: string) {
    this.luminarias = { SinAsignar: 0, ProgramadaReparacion: 0, Pendiente: 0, EnProcesoReparacion: 0, Otro: 0, Funcionando: 0, Garantias: 0, ReparacionCircuito: 0, TotalRepair: 0 };
    this.incidentsService
      .getIncidents(q, type, technology, problem, status, start_day, end_day)
      .subscribe((reportedElementsResponse: ReportedElementsResponse) => {        
        this.reported_elements = new ReportedElements(reportedElementsResponse.reported_elements);
        this.reported_elements.circuits = this.reported_elements.circuits.filter(circuit => circuit.lampposts.find(lamppost => lamppost.luminaires.length > 0));
        this.reported_elements.lampposts = this.reported_elements.lampposts.filter(lamppost => lamppost.luminaires.length);

        reportedElementsResponse.reported_elements.circuits.forEach(circuit => {
          circuit.lampposts.forEach(lamppost => {
            lamppost.luminaires.forEach(luminaire => {
              this.addLuminaireIncidents(luminaire);
            });
          });
        });

        reportedElementsResponse.reported_elements.lampposts.forEach(item => {
          if (item.luminaires.length) {
            item.luminaires.forEach(luminaire => {
              this.addLuminaireIncidents(luminaire);
            });
          }
        });

        reportedElementsResponse.reported_elements.luminaires.forEach(luminaire => {
          this.addLuminaireIncidents(luminaire);
        });

        this.porcentajeLuminariasFuncionando = (this.reported_elements.totalLuminaires / (this.luminarias.TotalRepair + this.reported_elements.totalLuminaires)) * 100;
        if (this.showMap) {
          this.showMap = false;
          window.setTimeout(() => {
            this.showMap = true;
          }, 0);
        }
      }, error => {
        this.toastr.error(error);
      });
  }

  getVehiclesLocation(resetMap: boolean = true): void {
    if (resetMap) {
      this.clearVehicleMarkers();
    }
    this.incidentsService.getVehiclesLocation().subscribe(
      (vehicles: VehiclesLocation[]) => {
        this.vehicles = vehicles.map((x) => new VehiclesLocation(x));
        // Aquí agregar lógica para crear los nuevos marcadores
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }
  


  clearVehicleMarkers(): void {
    if (this.vehicles && Array.isArray(this.vehicles)) {
      this.vehicles.forEach(vehicle => {
        if (vehicle.marker && vehicle.marker instanceof google.maps.Marker) {
          vehicle.marker.setMap(null); // Remueve el marcador del mapa
          vehicle.marker = null; // Elimina la referencia al marcador
        }
  
        // Borra la latitud y longitud del vehículo
        if (vehicle.latitude) {
          vehicle.latitude = null;
        }
        if (vehicle.longitude) {
          vehicle.longitude = null;
        }
      });
  
      // Llama al método del servicio para notificar que los marcadores han sido limpiados
      this.markerService.clearMarkers();
    }
  }
  
  private getProjectLuminaires() {
    this.project = this.currentProjectService.getCurrentProject();
    this.adminService.getProject(this.project.id)
      .subscribe(result => {
        const slug = result.project.slug;
        this.incidentsService.getProjectLuminaires(slug)
          .subscribe((luminaires: Luminaire[]) => {
            this.projectLuminaires = luminaires;
          }, error => {
            this.toastr.error(error);
          });
      }, error => {
        this.toastr.error(error);
      });
  }

  onSearchPress({ value }: { value: SearchValues }) {
    const start_formDate = this.incidentsSearchForm.get("start_day").value;
    const end_formDate = this.incidentsSearchForm.get("end_day").value;
    let start_day, end_day;
    if (start_formDate) {
      start_day = `${start_formDate.year}-${start_formDate.month}-${start_formDate.day}`;
    }

    if (end_formDate) {
      end_day = `${end_formDate.year}-${end_formDate.month}-${end_formDate.day}`;
    }

    const statusParam = Array.isArray(value.status) ? value.status.join(',') : value.status;

    this.router.navigate(['/u/call-center/incidents'],
      {
        relativeTo: this.route,
        queryParams: {
          q: value.q,
          type: value.type,
          technology: value.technology,
          problem: value.problem,
          status: statusParam,
          start_day: start_day,
          end_day: end_day
        }
      });
  }

  private populateForm(q: string, type: string, technology: string, problem: string, status: string, start_day: NgbDate, end_day: NgbDate) {
    const statusArray = status.split(',').map(s => s.trim());  
    this.incidentsSearchForm.patchValue({
      q: q,
      type: type,
      technology: technology,
      problem: problem,
      status: statusArray, 
      start_day: start_day,
      end_day: end_day
    });
  }
  

  // Deprecated 
  createTask() {
    if (this.getSelects == '') {
      this.modalService.open(this.modalAviso).result.then(result => { }, reason => { });
      return
    }
    this.showNewReparationTask = true;
  }

  openModal(content: any) {
    this.showTaskMethodType = true;
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-title' });
    this.modalRef.result.then((result) => {
      this.resetFormValues();
    }, (reason) => {
      this.resetFormValues();
      this.handleModalDismiss(true);    
    });
  }

  onModalClose() {
    this.modalRef.dismiss('Cross click');
    this.resetFormValues();
    this.showTaskMethodType = false;
    this.isLoading = false;
  }

  handleModalDismiss(abruptly: boolean) {
    if (abruptly) {
      this.onModalClose()
    } else {
      this.resetFormValues();
      this.modalRef.close();
    }
  }
  
  resetFormValues() {
    this.automated_luminaires = 0;
    this.work_type = '';
    this.searchTerm = '';
    this.showAllSectors = false;
    this.selectedSectors = [];
    this.sectors.forEach(sector => sector.checked = false);
  }

  onSelectedMethodType(event) {
    if (event.srcElement.value == 'undefined') {
      this.manualMethod = false;
      this.automaticMethod = false;
    } else if (event.srcElement.value == 'automatized'){
      this.automaticMethod = true;
      this.manualMethod = true;
    } else {
      this.manualMethod = true;
      this.automaticMethod = false;
    }
  }

  workTypeChanged(workType: string): void {
    this.work_type = workType;
    if (workType === '4') {
      this.isLoadingSectors = true;
      this.sectorsLoaded = false;
      this.incidentsService.getSectors().subscribe(
        (data: Sector[]) => {
          this.sectors = data;
          this.isLoadingSectors = false;
          this.sectorsLoaded = true;
        },
        (error) => {
          console.error('Error fetching sectors', error);
          this.isLoading = false;
        }
      );
    } else {
      this.sectorsLoaded = false;
    }
  }

  automatizedSelection() {
    if(this.work_type === undefined || this.automated_luminaires === undefined) {      
      return;
    }
    this.isLoading = true;
    this.incidentsRoute4MeService.get_luminaires_incident_route4_me_with_sectors(this.work_type, this.selectedSectors).subscribe(elements => {
      const elementsSelected = elements.map(element => element.id).slice(0, this.automated_luminaires);

      for (const circuits of this.reported_elements.circuits) {
        for (const lamppost of circuits.lampposts) {
          for (const luminaire of lamppost.luminaires) {
            if(elementsSelected.some(x => x == luminaire.id)) {
              luminaire.isSelected = true
            } else {
              luminaire.isSelected = false
            }
          }
        }
      }

      for (const lamppost of this.reported_elements.lampposts) {
        for (const luminaire of lamppost.luminaires) {
          if(elementsSelected.some(x => x == luminaire.id)) {
            luminaire.isSelected = true
          } else {
            luminaire.isSelected = false
          }
        }
      }

      for (const luminaire of this.reported_elements.luminaires) {
        if(elementsSelected.some(x => x == luminaire.id)) {
          luminaire.isSelected = true
        } else {
          luminaire.isSelected = false
        }
      }
      this.isLoading = false;
      this.handleModalDismiss(false);
    }, error => {
      console.error('Error in automatizedSelection:', error);
      this.isLoading = false;
    })
  }

  createPhaseTask() {

    if (this.getSelects == '') {
      this.modalService.open(this.modalAviso).result.then(result => { }, reason => { });
      return
    }

    const lumsIds: string[] = [];
    for (const luminaire of this.reported_elements.luminaires) {
      if (luminaire.isSelected) {
        lumsIds.push(luminaire.id);
      }
    }

    for (const lamppost of this.reported_elements.lampposts) {
      for (const luminaire of lamppost.luminaires) {
        if (luminaire.isSelected) {
          lumsIds.push(luminaire.id);
        }
      }
    }

    for (const circuits of this.reported_elements.circuits) {
      for (const lamppost of circuits.lampposts) {
        for (const luminaire of lamppost.luminaires) {
          if (luminaire.isSelected) {
            lumsIds.push(luminaire.id);
          }
        }
      }
    }

    this.incidentsService.downloadComplementaryTicket();
    this.incidentsService.createTaskRepair(lumsIds);

  }

  notApplicable() {
    const requestPayload = new ReportedElementsRejectPayload(this.reported_elements);
    this.incidentsService.updateReported_elements_Reject(requestPayload)
      .subscribe((response) => {
        window.location.reload();
      }, (error: any) => {
        this.toastr.error(error);
      });
  }

  seeOnMap(circuit: any) {
    this.selectedCircuit = circuit;
    // this.vehicles;
    this.showMap = true;
  }

  cancelAutomaticSelection() {
    this.showTaskMethodType = !this.showTaskMethodType;
    this.reported_elements.circuits.forEach(circuit => {
      circuit.lampposts.forEach(lamppost => {
        lamppost.luminaires.forEach(luminaire => {
          luminaire.isSelected = false;
        });
      });
    });
    this.reported_elements.lampposts.forEach(lamppost => {
      lamppost.luminaires.forEach(luminaire => {
        luminaire.isSelected = false;
      });
    });
    this.reported_elements.luminaires.forEach(luminaire => {
      luminaire.isSelected = false;
    });
  }

  showNotApplicable() {
    if (this.getSelects === '') {
      this.modalService.open(this.modalAviso).result.then(result => { }, reason => { });
      return;
    }
    this.modalService.open(this.modalNotApplicable).result.then(result => {
      this.notApplicable();
    }, reason => { });
  }

  get getSelects(): string {
    let total = 0;
    let message = '';
    for (let circuit of this.reported_elements.circuits) {
      for (let lamppost of circuit.lampposts) {
        for (let luminaire of lamppost.luminaires) {
          if (luminaire.isSelected) {
            total = total + 1;
          }
        }
      }
    }
    for (let lamppost of this.reported_elements.lampposts) {
      for (let luminaire of lamppost.luminaires) {
        if (luminaire.isSelected) {
          total = total + 1;
        }
      }
    }
    for (let luminaire of this.reported_elements.luminaires) {
      if (luminaire.isSelected) {
        total = total + 1;
      }
    }
    if (total == 1) {
      message = '1 Luminaria seleccionada'
    }
    if (total > 1) {
      message = `${total} Luminarias seleccionadas`
    }
    return message;
  }

  cancel() {
    this.showMap = false;
    this.showNewReparationTask = false;
  }

}
