import { Location } from "./luminaire";

export interface IVehiclesLocation {
    name: string;
    serialNumber: string;
    troop_id: string;
    latitude: number;
    longitude: number;
    marker: google.maps.Marker;
    GTVehicle_Id: string;
    totalHours: number;
    hoursFormated: string;
    locations: Location[];
}

export class VehiclesLocation implements IVehiclesLocation {
    name: string;
    serialNumber: string;
    troop_id: string;
    latitude: number;
    longitude: number;
    event: string;
    isSelected: boolean;
    marker: google.maps.Marker;
    GTVehicle_Id: string;
    hoursFormated: string;
    totalHours: number;
    locations: Location[];
    polyline: google.maps.Polyline;

    constructor(attrs: any, event: string = 'normal') {
      this.GTVehicle_Id = attrs.GTVehicle_Id;
      this.name = attrs.name;
      this.serialNumber = attrs.serialNumber;
      this.troop_id = attrs.troop_id;
      this.latitude = attrs.latitude;
      this.longitude = attrs.longitude;
      this.event = event;
      this.totalHours = attrs.totalHours;
      this.hoursFormated = attrs.hoursFormated;
      this.locations = attrs.locations;
      this.isSelected = false;
    }

    // tslint:disable-next-line: member-ordering
    readonly markerIcons = {
      vechiclesIcon : {
          normal: 'assets/img/truck-',
          selected: 'assets/img/svg/truck-selected.svg',
          hover: 'assets/img/svg/truck-selected.svg'
      }
      };

      generate_polyline(map, color) {
        this.polyline = new google.maps.Polyline({
          icons: [
            {
              icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
              repeat: '70px'
            }
          ],
          path: this.locations.map(point => new google.maps.LatLng(point.latitude, point.longitude)),
          geodesic: true,
          strokeColor: color,
          strokeOpacity: 1,
          strokeWeight: 3,
          map: map,
          visible: true
        });
      }

      get_icon(): object {
        return {
          url: this.markerIcons.vechiclesIcon[
            this.event === 'normal'
              ? this.isSelected
                ? 'selected'
                : 'normal'
              : this.event
          ] + this.name + '.png',
          scaledSize: this.isSelected
            ? new google.maps.Size(34, 50)
            : new google.maps.Size(60, 30),
        };
      }

      upadeIcon(): void {
        this.marker.setIcon(this.get_icon());
      }

      paintMarker(map: any): void {
        let latitude = this.latitude;
        let longitude = this.longitude;
        this.event = this.isSelected ? 'selected' : 'normal';
        this.marker = new google.maps.Marker({
          title: this.name,
          position: new google.maps.LatLng(latitude, longitude),
          icon: this.get_icon(),
          map: map,
          zIndex: 9999 // Asignar un z-index alto por defecto
        });
      }
      
    
}


