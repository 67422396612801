import { Material } from "./material";
import { DiagnosisEnum } from './diagnosis-enum';
import { StatusEnum } from './status-enum';
import { WorkDoneEnum } from "./work-done-enum";
import { RepairProblemEnum } from "./repair-problem-enum";

export class RepairV2 {
    id: string;
    diagnosis: number;
    diagnosisName: string;
    status: number;
    statusName: string;
    work_done: number;
    work_doneName: string;
    problem: number;
    problemName: string;
    comment: string;

    first_problem_image_url: string | null;
    second_problem_image_url: string | null;
    first_material_image_url: string | null;
    second_material_image_url: string | null;
    third_material_image_url: string | null;
    fourth_material_image_url: string | null;
    first_repair_image_url: string | null;
    second_repair_image_url: string | null;

    used_items: any[];
    recovered_items: any[];

    private static readonly BASE_URL = "https://optima-energia.s3.amazonaws.com/uploads/repair";

    constructor(attrs: any) {
        this.id = attrs.id;
        this.diagnosis = attrs.diagnosis;
        this.diagnosisName = DiagnosisEnum[this.diagnosis];
        this.status = attrs.status;
        this.statusName = StatusEnum[this.status];
        this.work_done = attrs.work_done;
        this.work_doneName = WorkDoneEnum[this.work_done];
        this.problem = attrs.problem;
        this.problemName = RepairProblemEnum[this.problem];
        this.comment = attrs.comment;

        // Construcción de URLs dinámicas
        this.first_problem_image_url = this.buildImageUrl("first_problem_image", attrs.first_problem_image_url);
        this.second_problem_image_url = this.buildImageUrl("second_problem_image", attrs.second_problem_image_url);
        
        this.first_material_image_url = this.buildImageUrl("first_material_image", attrs.first_material_image_url);
        this.second_material_image_url = this.buildImageUrl("second_material_image", attrs.second_material_image_url);
        this.third_material_image_url = this.buildImageUrl("third_material_image", attrs.third_material_image_url);
        this.fourth_material_image_url = this.buildImageUrl("fourth_material_image", attrs.fourth_material_image_url);
        
        this.first_repair_image_url = this.buildImageUrl("first_repair_image", attrs.first_repair_image_url);
        this.second_repair_image_url = this.buildImageUrl("second_repair_image", attrs.second_repair_image_url);

        // Arrays de items
        this.used_items = attrs.usedItems ? attrs.usedItems.map(item => new Used_item(item)) : [];
        this.recovered_items = attrs.recoveredItems ? attrs.recoveredItems.map(item => new Recovered_item(item)) : [];
    }

    private buildImageUrl(type: string, fileName: string | null): string | null {
        return fileName ? `${RepairV2.BASE_URL}/${type}/${this.id}/${fileName}` : null;
    }

}

export class Used_item {
    id: string
    quantity: number
    material: Material

    constructor(attrs: any) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = new Material(attrs.material);
    }
}
export class Recovered_item {
    id: string
    quantity: number
    material: Material

    constructor(attrs: any) {
        this.id = attrs.id;
        this.quantity = attrs.quantity;
        this.material = new Material(attrs.material);
    }
}
